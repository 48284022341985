import {
  PUBLISH_SETTINGS_POPUP_HIDE_EXPIRY_DAYS,
  PUBLISH_SETTINGS_POPUP_HIDE_STORAGE_KEY,
} from '@/constants';
import { posthog } from 'posthog-js';
import { SetState, GetState } from 'zustand';

export type IPublishSettingsPopupSlice = {
  publishSettings: {
    open: boolean;
    openPopup: () => void;
    closePopup: () => void;
  };
};

export type IFeatureFlagSlice = {
  featureFlags: {
    [key: string]: boolean;
  };
  updateFeatureFlags: (ff: { [key: string]: boolean }) => void;
};

type AppSlice = IPublishSettingsPopupSlice & IFeatureFlagSlice;

export type StoreSlice<T> = (set: SetState<AppSlice>, get: GetState<AppSlice>) => T;

export const createPublishSettingsSlice: StoreSlice<IPublishSettingsPopupSlice> = (set) => {
  const openPopup = () => {
    if (
      localStorage &&
      (localStorage.getItem(PUBLISH_SETTINGS_POPUP_HIDE_STORAGE_KEY) === null ||
        Number(localStorage.getItem(PUBLISH_SETTINGS_POPUP_HIDE_STORAGE_KEY)) < Date.now())
    ) {
      set((state) => ({
        ...state,
        publishSettings: {
          ...state.publishSettings,
          open: true,
        },
      }));
      if (Number(localStorage.getItem(PUBLISH_SETTINGS_POPUP_HIDE_STORAGE_KEY)) < Date.now()) {
        localStorage.removeItem(PUBLISH_SETTINGS_POPUP_HIDE_STORAGE_KEY);
      }
    }
  };
  const closePopup = () => {
    const addDaysTimestamp = PUBLISH_SETTINGS_POPUP_HIDE_EXPIRY_DAYS * 24 * 60 * 60 * 1000;
    const expiryTimestamp = Date.now() + addDaysTimestamp;
    localStorage.setItem(PUBLISH_SETTINGS_POPUP_HIDE_STORAGE_KEY, String(expiryTimestamp));

    set((state) => ({
      ...state,
      publishSettings: {
        ...state.publishSettings,
        open: false,
      },
    }));
  };

  const state: IPublishSettingsPopupSlice = {
    publishSettings: {
      open: false,
      openPopup,
      closePopup,
    },
  };
  return state;
};

export const createFeatureFlagSlice: StoreSlice<IFeatureFlagSlice> = (set) => {
  const setFlag = (ff: Record<string, boolean>) => {
    set((s) => ({
      ...s,
      featureFlags: {
        ...s.featureFlags,
        ...ff,
      },
    }));
  };

  posthog.onFeatureFlags((posthogFlags) => {
    const flagsMap = posthogFlags.reduce(
      (acc, curr) => ({
        ...acc,
        [curr]: true,
      }),
      {}
    );
    setFlag(flagsMap);
  });

  const state: IFeatureFlagSlice = {
    featureFlags: {},
    updateFeatureFlags: setFlag,
  };

  return state;
};

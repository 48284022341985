import { API as c } from "../../api/api.js";
import { ICollectionIntegration as d } from "../../types.js";
const u = (e) => async ({ spaceID: n, id: t, appName: o }) => {
  if (!n)
    return;
  const { getState: r } = e, { tokenHandlers: s } = r(), { spacePermissionTokenHandler: a, subscriptionTokenHandler: i } = s;
  return (await c.getOrCreateInstance().app().delete(n, t, o, a, i)).data;
}, H = (e) => async (n) => {
  if (!n)
    return;
  const { getState: t } = e, { tokenHandlers: o } = t(), { spacePermissionTokenHandler: r, subscriptionTokenHandler: s } = o;
  return (await c.getOrCreateInstance().stripe().checkConnection(n, r, s)).data;
}, g = (e) => async ({ spaceID: n, refreshURL: t, returnURL: o }) => {
  if (!n)
    return;
  const { getState: r } = e, { tokenHandlers: s } = r(), { spacePermissionTokenHandler: a, subscriptionTokenHandler: i } = s;
  return (await c.getOrCreateInstance().stripe().connect(n, t, o, a, i)).data;
}, C = (e) => async ({ spaceID: n, refreshURL: t, returnURL: o }) => {
  if (!n)
    return;
  const { getState: r } = e, { tokenHandlers: s } = r(), { spacePermissionTokenHandler: a, subscriptionTokenHandler: i } = s;
  return (await c.getOrCreateInstance().paypal().connect(n, t, o, a, i)).data;
}, T = (e) => async (n) => {
  if (!n)
    return;
  const { getState: t } = e, { tokenHandlers: o } = t(), { spacePermissionTokenHandler: r, subscriptionTokenHandler: s } = o;
  return (await c.getOrCreateInstance().paypal().checkConnection(n, r, s)).data;
}, m = (e) => async (n) => {
  if (!n)
    return;
  const { getState: t } = e, { tokenHandlers: o } = t(), { spacePermissionTokenHandler: r, subscriptionTokenHandler: s } = o;
  return (await c.getOrCreateInstance().app().checkOpenAIConnection(n, r, s)).data;
}, y = (e) => async ({ spaceID: n, secret: t }) => {
  if (!n)
    return;
  const { getState: o } = e, { tokenHandlers: r } = o(), { spacePermissionTokenHandler: s, subscriptionTokenHandler: a } = r;
  return (await c.getOrCreateInstance().app().create(n, d.OPENAI, {
    secret: t
  }, s, a)).data;
};
export {
  m as checkOpenAIConnection,
  T as checkPaypalConnection,
  H as checkStripeConnection,
  y as connectOpenAI,
  C as connectPaypal,
  g as connectStripe,
  u as deleteApp
};

import { makeApiSliceCreator as i } from "@typedream/zustand-query";
import { listDocuments as m, uploadDocument as n } from "./actions.js";
const u = i("documentsApi", (t) => ({
  endpoints: (e) => ({
    listDocuments: e.query(m(t)),
    uploadDocument: e.mutation(n(t), {
      invalidateQueries: ({ args: o }) => [["listDocuments", o[0]]]
    })
  })
}));
export {
  u as createDocumentsApiSlice
};

var o = Object.defineProperty;
var p = (s, e, t) => e in s ? o(s, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : s[e] = t;
var i = (s, e, t) => (p(s, typeof e != "symbol" ? e + "" : e, t), t);
const n = [
  "title",
  "slug",
  "public",
  "published_at"
], r = {
  selection: "Selection",
  multiselection: "Multi-selection",
  text: "Text",
  number: "Number",
  date: "Date",
  image: "Image",
  boolean: "Checkbox",
  rich_text: "Rich Text",
  url: "URL",
  object: "Object",
  slug: "Text"
}, d = class {
  constructor(e) {
    i(this, "id");
    i(this, "deleted_at");
    i(this, "updated_at");
    i(this, "created_at");
    i(this, "space_id");
    i(this, "schemaVersion", 0);
    i(this, "public_permission");
    i(this, "type");
    i(this, "field");
    var t;
    if (e && d.isCMS(e)) {
      this.id = e.id, this.space_id = e.space_id, this.deleted_at = new Date().toString(), this.updated_at = e.updated_at, this.created_at = new Date().toString(), this.type = "cms", this.public_permission = 3, this.field = {
        name: e.field.name,
        schemaVersion: this.schemaVersion,
        schema: e.field.schema,
        preset_schema: e.field.preset_schema,
        schemaOrder: e.field.schemaOrder
      }, ((t = this.field.schemaOrder) == null ? void 0 : t.length) <= 0 && (this.field.schemaOrder = [...n, ...Object.keys(this.field.schema)]);
      return;
    }
    this.id = "", this.space_id = "", this.deleted_at = new Date().toString(), this.updated_at = new Date().toString(), this.created_at = new Date().toString(), this.public_permission = 3, this.field = d.getDefaultCMSField(), this.type = "cms";
  }
  static isCMS(e) {
    return e && e.type === "cms";
  }
  static getDefaultCMSField() {
    return {
      name: "Blog",
      schemaVersion: 0,
      schema: {
        0: {
          name: "Image",
          type: "image",
          deleted: !1
        }
      },
      preset_schema: {
        title: {
          name: "Title",
          type: "text",
          deleted: !1
        },
        slug: {
          name: "Slug",
          type: "text",
          deleted: !1
        },
        metadata: {
          name: "Metadata",
          type: "object",
          deleted: !1
        },
        published_at: {
          name: "Published At",
          type: "date",
          deleted: !1
        },
        content: {
          name: "Content",
          type: "rich_text",
          deleted: !1
        },
        published_content: {
          name: "Published Content",
          type: "rich_text",
          deleted: !1
        }
      },
      schemaOrder: [...n, "0"]
    };
  }
  static isSchemaTypeWithOptions(e) {
    return e && (e.type === "selection" || e.type === "multiselection");
  }
  static isTypeWithOptions(e) {
    return e === "selection" || e === "multiselection";
  }
  static getDefaultCMSItems(e) {
    return [
      {
        title: "How to sell your digital products",
        snippet: "Start selling your digital products with a few simple steps.",
        slug: "how-to-sell-your-digital-products",
        publishedAt: new Date(),
        image: "https://api.typedream.com/v0/document/public/06acde81-5a0d-4f50-a48d-eaf4fbc08f8b/2UKkJWvDgmZiA2bBDLXX2f0tRE1_philip-jahn-PYkpulrIMG0-unsplash_Medium.jpeg"
      },
      {
        title: "How to start email marketing",
        snippet: "Email marketing is a great way to reach your customers.",
        slug: "how-to-start-email-marketing",
        publishedAt: new Date(),
        image: "https://api.typedream.com/v0/document/public/08ed9f24-125c-4caa-9d3b-c1233537e0c6/2UQ5bkdooaR5OKEmoFxITtRaXnc_orva-studio-YC8qqp50BdA-unsplash_Medium.jpeg"
      },
      {
        title: "Start your creator journey with a freebie",
        snippet: "One of the best ways to get started is to give away something for free.",
        slug: "start-your-creator-journey-with-a-freebie",
        publishedAt: new Date(),
        image: "https://api.typedream.com/v0/document/public/08ed9f24-125c-4caa-9d3b-c1233537e0c6/2UQ5eB7UTWLOzXlXbKSswM6YsDW_nadine-marfurt-BHZPATWnn-o-unsplash_Medium.jpeg"
      },
      {
        title: "Why you should start a blog",
        snippet: "Blogging is a great way to establish yourself as an expert in your field.",
        slug: "why-you-should-start-a-blog",
        publishedAt: new Date(),
        image: "https://api.typedream.com/v0/document/public/08ed9f24-125c-4caa-9d3b-c1233537e0c6/2UQ5jWjzJohlz5LCCmozkTeMJcy_matt-artz-GmT0kql0k40-unsplash_Medium.jpeg"
      }
    ].map((a) => ({
      collection_id: e,
      deleted: !1,
      public: !0,
      document: {
        fields: {
          0: a.image
        },
        preset_fields: {
          title: a.title,
          slug: a.slug,
          published_at: a.publishedAt.toString(),
          metadata: {
            metatags: {
              title: a.title,
              description: a.snippet
            }
          }
        }
      }
    }));
  }
  static addSchemaField(e, t) {
    for (let a = 0; a < 100; a += 1)
      if (!e[a.toString()])
        return d.isTypeWithOptions(t) ? {
          schema: {
            ...e,
            [a.toString()]: {
              name: r[t],
              type: t,
              options: {
                0: {
                  name: "Option 1",
                  deleted: !1
                },
                1: {
                  name: "Option 2",
                  deleted: !1
                }
              },
              deleted: !1
            }
          },
          addedKey: a.toString()
        } : {
          schema: {
            ...e,
            [a.toString()]: {
              name: r[t],
              type: t,
              deleted: !1
            }
          },
          addedKey: a.toString()
        };
    throw new Error("Cannot add more than 100 fields");
  }
};
let l = d;
i(l, "possiblePresetSchemaKeys", [
  "title",
  "slug",
  "metadata",
  "published_at",
  "content",
  "published_content"
]), i(l, "nonSchemaKeys", ["public"]);
export {
  l as CMSCollection
};

'use client';

import { cva, type VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import { cn } from '../../utils';

// TODO: add dark mode
// Note:
// Currently, we use Tailwind's tokens directly. Hence, this component is not themeable.
const buttonVariants = cva(
  [
    // TODO: remove the preflight classes after replacing all old buttons
    'border-0 cursor-pointer normal-case font-[inherit] text-[100%] leading-[1.5] border-solid', // PREFLIGHT CLASSSES

    'inline-flex items-center justify-center rounded-lg font-medium focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 ring-offset-background',
    'px-4 py-[10px] whitespace-nowrap',
    'transition-all duration-150',
  ].join(' '),
  {
    variants: {
      variant: {
        default: 'bg-primary text-white hover:bg-primary-600 active:bg-primary-500 text-white',
        outline:
          'bg-transparent text-secondary dark:text-primary border border-border hover:shadow-lg ' +
          'active:shadow-lg active:bg-background-secondary active:text-primary dark:active:text-primary',
        secondary: cn(
          'bg-background-tertiary text-primary hover:bg-background-surface',
          'active:bg-background-tertiary active:text-primary'
        ),
        destructive: 'bg-red-400 text-white hover:bg-red-600 active:bg-red-500',
        'destructive-secondary':
          'bg-transparent text-red-400 border border-red-400 hover:shadow-lg ' +
          'active:shadow-lg active:bg-neutral-100 active:border-red-700 active:text-red-700',
        'destructive-soft':
          'bg-background-button-danger-soft text-danger hover:bg-red-50/50 active:bg-red-50/50 dark:hover:bg-red-950/50 dark:active:bg-red-950/50',
        ghost:
          'bg-transparent text-secondary hover:bg-background-tertiary hover:text-primary-soft' +
          'active:text-primary-soft active:bg-background-surface',
        // TODO: Register the black color shades to the Tailwind's color
        black: 'bg-[#131415] text-white hover:bg-[#2d2f36] active:bg-slate-900',
        invert:
          'bg-[#131415] dark:bg-white text-invert-primary hover:bg-[#2d2f36] dark:bg-slate-50 active:bg-slate-900 dark:active:bg-slate-100',
      },
      size: {
        md: 'text-sm',
        xs: 'text-xs',
        sm: 'text-xs font-medium px-2 py-1.5',
        lg: 'text-lg',
        '2xl': 'text-2xl gap-4 px-4 py-3',
      },
      isDisabled: {
        true:
          'opacity-50 shadow-none hover:shadow-none disabled:pointer-events-none disabled:cursor-not-allowed' +
          'active:shadow-none active:text-neutral-400',
        false: '',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'md',
    },
    compoundVariants: [
      {
        variant: [
          'default',
          'secondary',
          'destructive',
          'destructive-secondary',
          'destructive-soft',
          'black',
          'invert',
        ],
        isDisabled: true,
        className:
          'cursor-not-allowed opacity-40 border-none border-0 ' +
          'border-none border-0 shadow-none hover:shadow-none hover:bg-neutral-300 hover:text-neutral-400 ' +
          'active:bg-neutral-300 active:border-none active:border-0 active:shadow-none active:text-neutral-400',
      },
      {
        variant: ['outline'],
        isDisabled: true,
        className:
          'cursor-not-allowed bg-transparent text-neutral-400' +
          'border border-solid shadow-none hover:shadow-none hover:bg-neutral-100 hover:bg-opacity-50 ' +
          'active:shadow-none active:text-neutral-400',
      },
    ],
  }
);

const buttonIconLoadingVariant = cva('fill-current animate-spin leading-none', {
  variants: {
    size: {
      lg: 'text-xl',
      md: 'text-lg',
      sm: 'text-base',
      xs: 'text-sm',
      '2xl': 'text-2xl',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  /** React component for the left icon */
  leftIcon?: React.ReactNode;
  /** Additional classname for the left icon. Tips: use Tailwind's classes */
  leftIconClassName?: string;
  /** React component for the icon replacing the text */
  icon?: React.ReactNode;
  /** Additional classname for the icon replacing the text. Tips: use Tailwind's classes */
  iconClassName?: string;
  /** React component for the right icon */
  rightIcon?: React.ReactNode;
  /** Additional classname for the right icon. Tips: use Tailwind's classes */
  rightIconClassName?: string;
  /** Show loading state with spinner */
  isLoading?: boolean;
  /** Text to show when in loading state */
  loadingText?: string;
  /** Placement of the loading spinner if `loadingText` exist */
  loadingPlacement?: 'left' | 'right';
}

/**
 *
 * @example
 * ```tsx
 * <Button
 *   isLoading
 *   loadingText="Loading..."
 *   loadingPlacement="right"
 *   leftIcon={<FaUser />}
 *   onClick={onClick}
 * >
 *  Login
 * </Button>
 * ```
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      isDisabled,
      children,
      leftIcon,
      leftIconClassName,
      icon,
      iconClassName,
      rightIcon,
      rightIconClassName,
      isLoading,
      loadingText,
      onClick,
      ...props
    },
    ref
  ) => {
    return (
      <button
        className={cn(
          buttonVariants({ variant, size, className, isDisabled: isDisabled || isLoading })
        )}
        ref={ref}
        data-disabled={isDisabled}
        disabled={isDisabled || isLoading}
        onClick={(e) => (onClick && !isDisabled && !isLoading ? onClick(e) : undefined)}
        {...props}
      >
        {isLoading && (
          <span
            className={cn(
              'w-full flex justify-center items-center gap-2 relative',
              props.loadingPlacement === 'right' ? 'flex-row-reverse' : 'flex-row'
            )}
          >
            <span
              className={cn(
                loadingText ? 'relative' : 'absolute',
                'flex justify-center items-center'
              )}
            >
              <FaCircleNotch className={buttonIconLoadingVariant({ size })} />
            </span>

            {loadingText ? (
              <span>{loadingText}</span>
            ) : (
              // Prevent layout shift and maintain the width of the button
              <span className="opacity-0">{children}</span>
            )}
          </span>
        )}
        {!isLoading && (
          <>
            {leftIcon && (
              <span className={cn('fill-current mr-2 leading-none h-fit', leftIconClassName)}>
                {leftIcon}
              </span>
            )}
            {icon ? (
              <>
                <span
                  className={cn(
                    'flex items-center justify-center fill-current leading-none h-fit',
                    iconClassName
                  )}
                >
                  {icon}
                </span>
                <span className="sr-only">{children}</span>
              </>
            ) : (
              children
            )}
            {rightIcon && (
              <span className={cn('fill-current ml-2 leading-none h-fit', rightIconClassName)}>
                {rightIcon}
              </span>
            )}
          </>
        )}
      </button>
    );
  }
);
Button.displayName = 'Button';

export { Button, buttonVariants };

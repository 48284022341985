var E = Object.defineProperty;
var g = (u, e, t) => e in u ? E(u, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : u[e] = t;
var f = (u, e, t) => (g(u, typeof e != "symbol" ? e + "" : e, t), t);
import { ICollectionDataItemMetadataKeys as C } from "../../../types.js";
import { formatDate as H } from "../../../utils/formatDate.js";
import { FormCollection as N } from "../../../class/form.js";
import { CMSCollection as k } from "../../../class/cms.js";
import { CollectionAPI as y } from "../../collection/api.js";
class R {
  constructor(e) {
    f(this, "api");
    this.api = e;
  }
  getItemPageDataID(e) {
    const t = e == null ? void 0 : e.integration, n = e == null ? void 0 : e.tableID;
    return `${t}_${n}`;
  }
  constructGetDataResult(e) {
    var t;
    if (e.collection.type === "form") {
      const n = new N(e.collection), { schema: r } = n.field;
      return {
        data: (t = e.results) == null ? void 0 : t.map((s) => {
          if (!s.deleted && s.public)
            return {
              ...Object.entries(s.document).reduce((i, [w, c]) => {
                const T = parseInt(w, 10), h = r[T], d = N.isSchemaTypeWithOptions(h);
                let m = c;
                return d && (m = c.map((l) => {
                  var p;
                  return (p = h.options[l]) == null ? void 0 : p.name;
                })), { ...i, [w]: m };
              }, {}),
              created_at: s.created_at,
              id: s.id
            };
        }).filter((s) => s !== void 0),
        has_more: !!e.hasMore,
        next_cursor: `${e.nextOffset}`
      };
    }
    if (e.collection.type === "cms") {
      const n = new k(e.collection), { schema: r, preset_schema: a } = n.field, o = e.results;
      return {
        data: o == null ? void 0 : o.map((i) => {
          if (!i.deleted) {
            const { fields: w } = i.document, c = Object.entries(w).reduce((h, [d, m]) => {
              const l = r[d], p = k.isSchemaTypeWithOptions(l);
              let D = m;
              return p && (D = m.map((P) => {
                var b;
                return (b = l.options[P]) == null ? void 0 : b.name;
              })), l.type === "date" && (D = H(new Date(m))), { ...h, [d]: D };
            }, {}), T = Object.entries(i.document.preset_fields).reduce((h, [d, m]) => {
              const l = a[d];
              let p = m;
              return l.type === "date" && (p = H(new Date(m))), { ...h, [d]: p };
            }, {});
            return {
              ...c,
              ...T,
              created_at: i.created_at,
              id: i.id
            };
          }
        }).filter((i) => i !== void 0),
        has_more: !!e.hasMore,
        next_cursor: `${e.nextOffset}`
      };
    }
    return {
      data: [],
      has_more: !1,
      next_cursor: ""
    };
  }
  async getData(e, t, n, r, a) {
    var c;
    const o = t == null ? void 0 : t.tableID;
    if (!o)
      throw new Error(`Invalid collection id ${o}`);
    const s = (c = t == null ? void 0 : t.metadata) == null ? void 0 : c[C.MAX_ITEMS], I = n ? parseInt(n, 10) : 0, i = {
      limit: s,
      offset: I
    }, w = await new y(this.api).queryItems(i, e, o, r, a);
    return this.constructGetDataResult(w);
  }
  tableConnected(e) {
    return e && (e == null ? void 0 : e.tableID);
  }
  getDataQuery(e, t, n) {
    throw new Error("Not implemented");
  }
  getDataByID(e, t, n, r, a) {
    throw new Error("Not implemented");
  }
  listTables(e, t, n) {
    throw new Error("Not implemented");
  }
  getTable(e, t, n, r) {
    throw new Error("Not implemented");
  }
  getTableInfo(e, t, n, r) {
    throw new Error("Not implemented");
  }
  getColumnNames(e, t, n, r) {
    throw new Error("Not implemented");
  }
  getColumnSelectOptions(e, t, n, r, a) {
    throw new Error("Not implemented");
  }
  addColumnNames(e, t, n, r, a) {
    throw new Error("Not implemented");
  }
  getDefaultItemPageSchema(e) {
    throw new Error("Not implemented");
  }
  connectCollectionGenerateColumns(e, t, n, r) {
    throw new Error("Not implemented");
  }
  prefillDefaultItemPage(e, t, n, r, a, o, s, I) {
    throw new Error("Not implemented");
  }
  contentToJSONFileURL(e, t, n, r, a, o) {
    throw new Error("Not implemented");
  }
  JSONFileToContent(e, t) {
    throw new Error("Not implemented");
  }
  updateItem(e, t, n, r, a, o) {
    throw new Error("Not implemented");
  }
  nonEditableColumns() {
    throw new Error("Not implemented");
  }
  updateItemValidation(e, t) {
    throw new Error("Not implemented");
  }
  publishCollectionContent(e) {
    throw new Error("Not implemented");
  }
  createItem(e, t, n, r) {
    throw new Error("Not implemented");
  }
  search(e, t, n, r, a, o, s) {
    throw new Error("Not implemented");
  }
  getOAuthConnectURL(e, t) {
    throw new Error("Not implemented");
  }
  addTableID(e, t) {
    throw new Error("Not implemented");
  }
  importContentPage(e, t, n, r, a, o, s, I) {
    throw new Error("Not implemented");
  }
}
export {
  R as CmsAPP
};

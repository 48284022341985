import { makeApiSliceCreator as e } from "@typedream/zustand-query";
import { queryAssistants as a, getAssistant as n, createNewAssistant as i, createNewGPT as o, uploadFileGPT as A, updateAssistant as m, publishAssistant as r } from "./actions.js";
const p = e("assistant", (t) => ({
  endpoints: (s) => ({
    queryAssistants: s.query(a(t)),
    getAssistant: s.query(n(t)),
    createNewAssistant: s.mutation(i(t)),
    createNewGPT: s.mutation(o(t)),
    uploadFileGPT: s.mutation(A(t)),
    updateAssistant: s.mutation(m(t)),
    publishAssistant: s.mutation(r(t))
  })
}));
export {
  p as createAssistantApiSlice
};

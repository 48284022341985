import { SPACE_STORAGE_KEY as e } from "../constants/constants.js";
const r = (t) => t ? {
  id: t.id,
  routes: t.routes
} : null;
function l(t) {
  if (!t)
    return;
  if (t instanceof Array) {
    const i = t.map(r).filter((n) => !!n);
    localStorage == null || localStorage.setItem(e, JSON.stringify(i));
    return;
  }
  const o = [r(t)];
  localStorage == null || localStorage.setItem(e, JSON.stringify(o));
}
export {
  l as saveSpaceInLocalStorage
};

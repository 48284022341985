import { AccessTokenHandler as s } from "../../utils/jwt/accessTokenHandler.js";
const p = (n) => async ({ description: e, section: t, template_pack_id: a }) => {
  const o = s.getAccessToken(), r = await fetch("/api/ai/section", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${o == null ? void 0 : o.token}`
    },
    body: JSON.stringify({ description: e, section: t, template_pack_id: a })
  });
  if (!r.ok)
    throw new Error((await r.json()).error);
  return await r.json();
}, w = (n) => async ({ description: e, section: t, template_pack_id: a }) => {
  const o = await fetch(`/api/ai/section/${t.template}`, {
    method: "POST",
    body: JSON.stringify({ description: e, section: t, template_pack_id: a })
  });
  if (!o.ok)
    throw new Error((await o.json()).error);
  return await o.json();
}, h = (n) => async ({ prompt: e, template_pack_id: t, page_description: a }) => {
  const o = s.getAccessToken(), r = await fetch("/api/ai/sections", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${o == null ? void 0 : o.token}`
    },
    body: JSON.stringify({
      description: e,
      template_pack_id: t,
      page_description: a
    })
  });
  if (!r.ok)
    throw new Error((await r.json()).error);
  return await r.json();
}, y = (n) => async ({ prompt: e }) => {
  const t = await fetch("/api/ai/info", {
    method: "POST",
    body: JSON.stringify({
      description: e
    })
  });
  if (!t.ok)
    throw new Error((await t.json()).error);
  return await t.json();
}, f = (n) => async ({ prompt: e, product_context: t, keywords: a, brand_voice: o, personality: r }) => {
  const i = await fetch("/api/ai/onboarding/narrative/headline", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      prompt: e,
      product_context: t,
      keywords: a,
      brand_voice: o,
      personality: r
    })
  });
  if (!i.ok)
    throw new Error((await i.json()).error);
  return await i.json();
}, g = (n) => async ({ prompt: e, product_context: t }) => {
  const a = await fetch("/api/ai/onboarding/narrative/talking_points", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      prompt: e,
      product_context: t
    })
  });
  if (!a.ok)
    throw new Error((await a.json()).error);
  return await a.json();
}, A = (n) => async ({ prompt: e, product_context: t }) => {
  const a = await fetch("/api/ai/onboarding/narrative/keywords", {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      prompt: e,
      product_context: t
    })
  });
  if (!a.ok)
    throw new Error((await a.json()).error);
  return await a.json();
}, j = (n) => async (e) => {
  const t = s.getAccessToken(), a = await fetch("/api/ai/pages", {
    method: "POST",
    headers: {
      Authorization: `Bearer ${t == null ? void 0 : t.token}`
    },
    body: JSON.stringify(e)
  });
  if (!a.ok)
    throw new Error((await a.json()).error);
  return await a.json();
};
export {
  f as generateAIHeadlines,
  A as generateAIKeywords,
  j as generateAIPages,
  g as generateAITalkingPoints,
  w as generateAiSectionTemplateData,
  h as generateAiSections,
  y as generateAiSiteInfo,
  p as selectAiTemplateKey
};

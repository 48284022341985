import { createSelector as L } from "reselect";
import { API as k } from "../../api/api.js";
import { isAllowedCreatePage as y } from "./utils.js";
import { MAX_SLUG_RETRY as D, DEFAULT_NEW_PAGE_SLUG_RANDOM_LENGTH as O, DEFAULT_NEW_PAGE_SLUG as A, DEFAULT_NEW_PAGE_NAME as R, RESERVED_PATHS as v, DUPLICATED_TABLE_ID_PREFIX as M } from "../../constants/constants.js";
import { saveContentLocal as N } from "../../api/page/offline.js";
import { getPageIDToPath as G } from "../../utils/getPageIDToPath.js";
import { App as z } from "../../api/app/app.js";
import { errToString as C } from "../../utils/errors.js";
const V = (c) => async (r) => {
  var o;
  if (!r)
    return {};
  const { getState: n } = c, { tokenHandlers: t } = n(), { spacePermissionTokenHandler: a } = t;
  if (!a)
    return {};
  const e = await k.getOrCreateInstance().page().list(r, a);
  return (o = e == null ? void 0 : e.data) == null ? void 0 : o.reduce((s, l) => ({ ...s, [l.id]: l }), {});
}, J = (c) => async (r, n) => {
  if (!r || !n)
    return;
  const { getState: t } = c, { tokenHandlers: a } = t(), e = k.getOrCreateInstance(), g = new z(e.api), { spacePermissionTokenHandler: o, subscriptionTokenHandler: s } = a;
  if (!o)
    return;
  const l = async (d) => {
    var f;
    const p = "You haven't connected the notion integration", w = "Request failed with status code 400", m = (f = d == null ? void 0 : d.customization) == null ? void 0 : f.collection_data;
    if (m) {
      const h = Object.entries(m).map(async ([U, H]) => {
        var S, _, b;
        let E = H;
        if ((S = g.app(H)) != null && S.tableConnected(H))
          try {
            const P = await ((_ = g.app(H)) == null ? void 0 : _.getData(r, H, "", o, s)), I = (P == null ? void 0 : P.data) || [];
            E = {
              ...H,
              data: I,
              hasMore: P == null ? void 0 : P.has_more,
              nextCursor: P == null ? void 0 : P.next_cursor,
              fetchedCursor: void 0,
              err: null
            };
          } catch (P) {
            C(P) !== p && (C(P) === w || ((b = H.tableID) == null ? void 0 : b.startsWith(M))) ? E = {
              ...H,
              err: "We can't connect to your database, please connect to a different one"
            } : E = {
              ...H,
              err: C(P)
            };
          }
        return [U, E];
      }), T = Object.fromEntries(await Promise.all(h));
      return {
        ...d,
        customization: {
          ...d == null ? void 0 : d.customization,
          collection_data: T
        }
      };
    }
    return d;
  }, i = await e.page().get(n, r, o);
  return await l(i == null ? void 0 : i.data);
}, K = (c) => async (r) => {
  var f, h;
  const { getState: n } = c, { tokenHandlers: { spacePermissionTokenHandler: t, subscriptionTokenHandler: a } } = n(), { spaceID: e, name: g = void 0, slug: o = void 0, pagePath: s, routeAtPath: l, isChildrenPageTemplate: i = !1 } = r, u = l.children ? Object.keys(l.children) : [];
  let d = A, p = 0;
  for (; u.includes(d) && p < D; ) {
    const T = Math.random().toString(36).substring(5, 5 + O);
    d = `${A}-${T}`, p += 1;
  }
  if (u.includes(d))
    throw new Error("Error creating page, unable to make a random slug for your new page");
  const w = y(d, s, l, i);
  if (w)
    throw new Error(w);
  const m = await k.getOrCreateInstance().page().create(e, g || R, o || d, s, i, t, a);
  return {
    page: (f = m == null ? void 0 : m.data) == null ? void 0 : f.page,
    space: (h = m == null ? void 0 : m.data) == null ? void 0 : h.space
  };
}, Q = (c) => async (r) => {
  var s;
  const { getState: n } = c, { tokenHandlers: { spacePermissionTokenHandler: t, subscriptionTokenHandler: a } } = n(), { spaceID: e, content: g } = r, o = await k.getOrCreateInstance().page().createHomePage(e, g, t, a);
  return (s = o == null ? void 0 : o.data) == null ? void 0 : s.page;
}, Z = (c) => async (r) => {
  const { getState: n } = c, { tokenHandlers: { spacePermissionTokenHandler: t, subscriptionTokenHandler: a } } = n(), { spaceID: e, pageID: g, slug: o, pagePath: s } = r;
  if (!g)
    throw new Error("Page id is not defined");
  await k.getOrCreateInstance().page().delete(g, e, o, s, t, a);
}, ee = (c) => {
  let r, n;
  return async (t) => {
    var m;
    const { getState: a } = c, { tokenHandlers: { spacePermissionTokenHandler: e, subscriptionTokenHandler: g }, pagesApi: o } = a(), { spaceID: s, pageID: l, newContent: i, force: u = !1 } = t, d = (m = o.endpoints.getPage.get(s, l)) == null ? void 0 : m.data, p = d == null ? void 0 : d.content_updated_at;
    if (p === r && i === n && !u)
      return;
    r = p, n = i;
    const { data: w } = await k.getOrCreateInstance().page().updateContent(l, s, i, p, u, e, g, () => {
      var h;
      const f = (h = o.endpoints.getPage.get(s, l)) == null ? void 0 : h.data;
      return N(f, i);
    });
    return w;
  };
}, W = (c, r) => {
  const { getState: n } = r;
  return L(n().spacesApi.endpoints.getSpace.select(c), n().pagesApi.endpoints.listPages.select(c), (t, a) => {
    var e;
    return (a == null ? void 0 : a.data) && ((e = t == null ? void 0 : t.data) == null ? void 0 : e.routes) ? G(t.data.routes, [], a.data) : {};
  });
}, te = (c) => async (r) => {
  var h;
  const { spaceID: n, pageID: t, name: a, slug: e, isPublic: g, allowDuplicate: o } = r, { getState: s } = c, { tokenHandlers: { spacePermissionTokenHandler: l, subscriptionTokenHandler: i }, spacesApi: u } = s(), d = W(n, c)(s()), { path: p } = d[t], w = p.length === 0, m = p.slice(0, -1);
  if (!t)
    throw new Error("Page id is not defined");
  if (!w && e.length <= 0 || a.length <= 0)
    throw new Error("Page URL or Name cannot be empty.");
  if ((p == null ? void 0 : p.length) === 1 && v.includes(e))
    throw new Error(`The URL "${e}" is reserved. Please use another URL for this page.`);
  let { routes: f } = u.endpoints.getSpace.get(n).data;
  if (m.forEach((T) => {
    f.children && (f = f.children[T]);
  }), ((h = f == null ? void 0 : f.children) == null ? void 0 : h[e]) && e !== p[p.length - 1])
    throw new Error(`The URL "${e}" is already used for a different page. Please use a unique URL for each page.`);
  await k.getOrCreateInstance().page().updateInfo(t, n, a, e, m, g, o, l, i);
}, ne = (c) => async (r) => {
  const { getState: n } = c, { spaceID: t, pageID: a, metadata: e, newTwitterImage: g, newFacebookImage: o } = r, { tokenHandlers: { spacePermissionTokenHandler: s, subscriptionTokenHandler: l } } = n(), i = k.getOrCreateInstance(), u = { ...e };
  if (g) {
    const p = (await i.document().upload(g, t, s, l)).data.file_name, w = i.document().getURL(p);
    u.twitter.image = w;
  }
  if (o) {
    const p = (await i.document().upload(o, t, s, l)).data.file_name, w = i.document().getURL(p);
    u.facebook.image = w;
  }
  await i.page().updateMetadata(a, t, u, s, l);
}, ae = (c) => async (r) => {
  const { getState: n } = c, { spaceID: t, pageID: a, customizationUpdateRequests: e } = r, { tokenHandlers: { spacePermissionTokenHandler: g, subscriptionTokenHandler: o } } = n(), { data: s } = await k.getOrCreateInstance().page().updateCustomizationPartial(a, t, e, g, o);
  return s;
}, oe = (c) => async (r) => {
  const { getState: n } = c, { create: t, destinationSpaceID: a, slug: e, pagePath: g, duplicateOwnPage: o } = r, { tokenHandlers: { spacePermissionTokenHandler: s, subscriptionTokenHandler: l } } = n(), i = await k.getOrCreateInstance().page().duplicate(t, a, e, g, o, s, l), u = i == null ? void 0 : i.data;
  if (!u)
    throw new Error("Unable to get destination space after duplicating");
  return u;
};
export {
  Q as createHomePage,
  K as createPage,
  Z as deletePage,
  oe as duplicatePage,
  J as getPage,
  V as listPages,
  ee as saveContent,
  ae as updatePageCustomization,
  te as updatePageInfo,
  ne as updatePageMetadata
};

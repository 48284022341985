import { FormInputTypesWithOptions as f } from "@typedream/core";
import { FormInputExtractor as u } from "./formInputExtractor.js";
import { IterateContent as m } from "./iterator.js";
function b(n) {
  if (!n)
    return [];
  const t = new u();
  return m([n], [t]), t.formInputs;
}
function k(n) {
  return n.reduce((t, e, r) => {
    switch (e.inputType) {
      case "selection":
      case "multiselection": {
        const p = Object.entries(e.options || {}).reduce((o, [s, a]) => ({
          ...o,
          [a.value]: {
            name: a.label
          }
        }), {});
        return {
          ...t,
          [e.name]: {
            name: e.label,
            type: e.inputType,
            options: p
          }
        };
      }
      case "text":
      case "number":
      default:
        return {
          ...t,
          [e.name]: {
            name: e.label,
            type: e.inputType
          }
        };
    }
  }, {});
}
function i(n) {
  return f.includes(n.type);
}
function c(n, t) {
  if (!n)
    return t;
  const e = { ...n };
  return Object.keys(e).forEach((o) => {
    const s = parseInt(o, 10);
    t[s] && (e[s] = t[s]);
  }), Object.keys(e).filter((o) => !t[parseInt(o, 10)]).forEach((o) => {
    e[parseInt(o, 10)].deleted = !0;
  }), Object.keys(t || {}).filter((o) => !n[parseInt(o, 10)]).reduce((o, s) => ({
    ...o,
    [s]: t[parseInt(s, 10)]
  }), { ...e });
}
function d(n, t) {
  if (!n && !t)
    return {};
  if (!n)
    return t;
  const e = { ...n };
  if (!t)
    return e.deleted = !0, e;
  if (i(e) && !i(t))
    delete e.options;
  else if (i(t))
    if (i(e)) {
      const r = c(e.options, t.options);
      e.options = r;
    } else
      e.options = t.options;
  return e.name = t.name, e.type = t.type, e;
}
function g(n, t) {
  const e = [];
  return new Set(Object.keys(n).concat(Object.keys(t))).forEach((o) => e.push(o)), e.reduce((o, s) => ({
    ...o,
    [s]: d(n[parseInt(s, 10)], t[parseInt(s, 10)])
  }), {});
}
export {
  b as extractFormInputBlocks,
  k as generateFormSchema,
  g as mergeFormSchema
};

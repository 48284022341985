import React from 'react';
import { Check } from '@styled-icons/boxicons-regular';
import {
  useClosePublishPopupDispatch,
  useOpenPublishPopup,
  useOpenPublishPopupDispatch,
} from '@/store';
import Popup from '../../sharedComponents/TextEditor/components/Popup';
import { Button, Text } from '@typedream/ui';

function PublishSettingsUpdatePopup() {
  const open = useOpenPublishPopup();
  const openPopup = useOpenPublishPopupDispatch();
  const closePopup = useClosePublishPopupDispatch();
  return (
    <Popup
      width={500}
      height={300}
      open={open}
      setopen={(o: boolean) => {
        if (o) {
          openPopup();
        } else {
          closePopup();
        }
      }}
      className="p-6"
    >
      <Check size={40} className="with-lighter-text-color mb-5" />
      <Text className="block !text-base !text-neutral-800 !mb-5">
        Press Publish to publish your settings changes
      </Text>
      <Text className="block !is-subtitle !text-slate-400 mb-5">
        After updating your settings on Site Settings or Page Settings, press <b>Publish</b> to
        publish your changes on desired pages.
      </Text>
      <div className="flex items-center">
        <Button onClick={closePopup}>Got it</Button>
      </div>
    </Popup>
  );
}

export default React.memo(PublishSettingsUpdatePopup);

import { API as b } from "../../api/api.js";
import { validUUID as p } from "../../utils/validUUID.js";
import { App as k } from "../../api/app/app.js";
const z = (n, e, i, c = !1) => {
  var l, s, d, o, f, a, _, m, y;
  const r = i.children ? Object.keys(i.children) : [];
  if (p(i == null ? void 0 : i.children_template_page_id))
    return `Error creating page, the path "/${e == null ? void 0 : e.join("/")}" is used for the children of a collection`;
  if (r.includes(n)) {
    if (p((s = (l = i == null ? void 0 : i.children) == null ? void 0 : l[n]) == null ? void 0 : s.page_id) && !c)
      return `Error creating page, the path "/${n}" is already taken`;
    if (p((o = (d = i == null ? void 0 : i.children) == null ? void 0 : d[n]) == null ? void 0 : o.children_template_page_id) && c)
      return `Error creating collection, the path "/${n}" is already taken by another collection`;
    if (((a = (f = i == null ? void 0 : i.children) == null ? void 0 : f[n]) == null ? void 0 : a.children) && ((y = Object.keys((m = (_ = i == null ? void 0 : i.children) == null ? void 0 : _[n]) == null ? void 0 : m.children)) == null ? void 0 : y.length) > 0 && c)
      return `Error creating collection, the path "/${n}" already have subpages and cannot be used as a folder for the collection items`;
  }
  return null;
}, O = (n, e) => {
  const i = [];
  return e && Object.entries(e).forEach(([c, r]) => {
    var d;
    const l = b.getOrCreateInstance(), s = (d = new k(l.api).app(r)) == null ? void 0 : d.getDataQuery(n, r, void 0);
    i.push({
      customization_path: ["collection_data", c],
      customization_value: {
        ...r,
        query: s
      }
    });
  }), i;
};
export {
  O as buildPageCustomizationCollectionDataUpdateRequests,
  z as isAllowedCreatePage
};

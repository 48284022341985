import { API as r } from "../../api/api.js";
import { SPACE_ACTION as h, SPACE_ROLE as O } from "../../types.js";
import { sanitizeSpace as f } from "../../utils/sanitizeSpace.js";
import { validFQDN as H } from "../../utils/validFQDN.js";
const U = () => async () => {
  var n;
  const t = await r.getOrCreateInstance().space().list();
  return Object.fromEntries((n = t == null ? void 0 : t.data) == null ? void 0 : n.map((a) => [a.id, f(a)]));
}, A = (t) => async (e) => {
  if (!e)
    return;
  const { getState: n } = t, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: s } } = n(), o = await r.getOrCreateInstance().space().get(e, a, s);
  return f(o == null ? void 0 : o.data);
}, N = (t) => async (e) => {
  const { getState: n } = t, { tokenHandlers: a } = n(), { spacePermissionTokenHandler: s } = a;
  return !e || !s ? [] : await r.getOrCreateInstance().spacePermission().listMembers(e, s);
}, L = () => async () => {
  const t = await r.getOrCreateInstance().space().getOrCreatePersonal();
  return f(t == null ? void 0 : t.data);
}, M = () => async (t) => {
  if (!t.name && !t.random_name)
    throw new Error("Site Name cannot be empty");
  if (!t.slug && !t.random_name)
    throw new Error("Site slug cannot be empty");
  const e = await r.getOrCreateInstance().space().create(t);
  return f(e == null ? void 0 : e.data);
}, z = () => async (t) => {
  if (!t.name && !t.random_name)
    throw new Error("Site Name cannot be empty");
  if (!t.slug && !t.random_name)
    throw new Error("Site slug cannot be empty");
  const e = await r.getOrCreateInstance().space().createNoPages(t);
  return f(e == null ? void 0 : e.data);
}, $ = (t) => async (e) => {
  const { getState: n } = t, { tokenHandlers: { spacePermissionTokenHandler: a } } = n();
  await r.getOrCreateInstance().space().delete(e, a);
}, q = (t) => {
  let e, n;
  return async (a) => {
    const { getState: s } = t, { tokenHandlers: { spacePermissionTokenHandler: o, subscriptionTokenHandler: c }, spacesApi: i } = s(), { spaceID: p, newNavbar: d, force: u = !1 } = a, m = i.endpoints.getSpace.get(p).data, l = m == null ? void 0 : m.updated_at;
    if (l === e && d === n && !u)
      return;
    e = l, n = d;
    const { data: g } = await r.getOrCreateInstance().space().updateNavbar(p, d, l, u, o, c, () => Promise.resolve());
    return g;
  };
}, F = (t) => async (e) => {
  const { getState: n } = t, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: s } } = n(), { spaceID: o, routes: c } = e;
  await r.getOrCreateInstance().space().updateRoutes(o, c, a, s);
}, j = (t) => async (e) => {
  var b;
  const { getState: n } = t, { spaceID: a, customization: s, allPublic: o, pageIDs: c, currentPageOnly: i, subscriptionPlanMap: p, typedreamURL: d, customDomain: u } = e, { tokenHandlers: { spacePermissionTokenHandler: m, subscriptionTokenHandler: l } } = n(), g = r.getOrCreateInstance(), w = await l.getSubscription(a), I = [
    d.includes("localhost") ? `http://${d.split("/")[0]}` : `https://${d.split("/")[0]}`
  ];
  u && I.push(`https://${u}`);
  let S;
  const P = !!(w != null && w.isAllowed(h.PublishUnlimitedPages, p)), k = i;
  if (k) {
    const C = P;
    S = await g.space().publishByPageIDs(a, { customization: s }, c, o, C, m, l);
  } else
    S = await g.space().publish(a, { customization: s }, P && o, m, l);
  const D = (b = S.data.pages) == null ? void 0 : b.map((C) => C.id), T = await g.revalidate().revalidate({
    spaceID: a,
    pageIDs: k ? c : D,
    allPages: !k,
    revalidateEndpoints: I
  }, m);
  return {
    space: S.data.space,
    revalidate_status: T.data.revalidate_status
  };
}, B = (t) => async (e) => {
  const { getState: n } = t, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: s } } = n(), { spaceID: o, customizationUpdateRequests: c } = e;
  if (!o)
    throw new Error("Space ID is not provided to update space customization");
  const { data: i } = await r.getOrCreateInstance().space().updateCustomizationPartial(o, c, a, s);
  return i;
}, x = (t) => async (e) => {
  const { getState: n } = t, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: s } } = n(), o = r.getOrCreateInstance(), { spaceID: c, name: i, slug: p, iconfile: d, originalIconURL: u } = e, m = { name: i, slug: p, icon: u };
  if (d) {
    const g = (await o.document().upload(d, c, a, s)).data.file_name, w = o.document().getURL(g);
    m.icon = w;
  }
  await o.space().updateSettings(c, m, a, s);
}, Q = (t) => async (e) => {
  const { getState: n } = t, { spaceID: a, metadata: s, twitterImage: o, facebookImage: c } = e, { tokenHandlers: { spacePermissionTokenHandler: i, subscriptionTokenHandler: p } } = n(), d = r.getOrCreateInstance(), u = { ...s };
  if (o) {
    const l = (await d.document().upload(o, a, i, p)).data.file_name, g = d.document().getURL(l);
    u.twitter.image = g;
  }
  if (c) {
    const l = (await d.document().upload(c, a, i, p)).data.file_name, g = d.document().getURL(l);
    u.facebook.image = g;
  }
  await d.space().updateMetadata(a, u, i, p);
}, G = (t) => async (e) => {
  const { getState: n } = t, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: s } } = n(), { spaceID: o, newMember: c } = e;
  if (!c.email)
    throw new Error("Email cannot be empty");
  if (!c.role || !Object.values(O).includes(c.role))
    throw new Error("Invalid role");
  if (!c.space_id || c.space_id !== o)
    throw new Error("Invalid space id");
  await r.getOrCreateInstance().spacePermission().addMember(o, c, a, s);
}, J = (t) => async (e) => {
  const { getState: n } = t, { tokenHandlers: { spacePermissionTokenHandler: a } } = n(), { spaceID: s, userID: o } = e;
  if (!o || !s)
    throw new Error("Space ID or User ID is not provided to remove this collaborator");
  await r.getOrCreateInstance().spacePermission().removeMember(s, o, a);
}, K = (t) => async (e) => {
  const { getState: n } = t, { tokenHandlers: { spacePermissionTokenHandler: a } } = n(), { spaceID: s, newOwner: o } = e;
  await r.getOrCreateInstance().spacePermission().transfer(s, o, a);
}, V = (t) => async (e) => {
  const { getState: n } = t, { spaceID: a } = e, s = e.customDomain.trim(), o = e.redirectCustomDomain ? e.redirectCustomDomain.trim() : void 0, { tokenHandlers: { spacePermissionTokenHandler: c, subscriptionTokenHandler: i } } = n();
  if (!s)
    throw new Error("Custom domain cannot be empty");
  if (!H(s))
    throw new Error(`The specified custom domain "${s}" is not a fully qualified domain name.`);
  if (o && !H(o))
    throw new Error(`The specified custom domain "${o}" is not a fully qualified domain name.`);
  await r.getOrCreateInstance().space().updateCustomDomain(a, s, o || "", c, i);
}, W = (t) => async (e) => {
  const { getState: n } = t, { spaceID: a } = e, s = e.redirectCustomDomain.trim(), { tokenHandlers: { spacePermissionTokenHandler: o, subscriptionTokenHandler: c } } = n();
  if (!s)
    throw new Error("Custom domain cannot be empty");
  if (!H(s))
    throw new Error(`The specified custom domain "${s}" is not a fully qualified domain name.`);
  await r.getOrCreateInstance().space().updateCustomDomainRedirect(a, s, o, c);
}, X = (t) => async (e) => {
  var i, p;
  const { getState: n } = t, { spacesApi: a, tokenHandlers: { spacePermissionTokenHandler: s, subscriptionTokenHandler: o } } = n(), c = (p = (i = a.endpoints.getSpace.get(e)) == null ? void 0 : i.data) == null ? void 0 : p.custom_domain;
  if (!c)
    throw new Error("Custom domain cannot be empty");
  await r.getOrCreateInstance().space().deleteCustomDomain(e, c, s, o);
}, Y = (t) => async (e) => {
  var i, p, d;
  const { getState: n } = t, { spacesApi: a, tokenHandlers: { spacePermissionTokenHandler: s, subscriptionTokenHandler: o } } = n(), c = (d = (p = (i = a.endpoints.getSpace.get(e)) == null ? void 0 : i.data) == null ? void 0 : p.customization) == null ? void 0 : d.redirect_custom_domain;
  if (!c)
    throw new Error("Custom domain cannot be empty");
  await r.getOrCreateInstance().space().deleteCustomDomainRedirect(e, c, s, o);
}, Z = (t) => async (e, n, a) => (await r.getOrCreateInstance().space().createUnclaimed(e, n, a)).data, ee = (t) => async ({ spaceID: e }) => (await r.getOrCreateInstance().space().claimSpace(e)).data;
export {
  G as addSpacePermission,
  ee as claimSpace,
  M as createSpace,
  z as createSpaceNoPages,
  Z as createUnclaimedSpace,
  X as deleteCustomDomain,
  Y as deleteCustomDomainRedirect,
  $ as deleteSpace,
  L as getOrCreatePersonalSpace,
  A as getSpace,
  N as getSpacePermissions,
  U as listSpaces,
  j as publish,
  J as removeSpacePermission,
  q as saveNavbar,
  K as transferSpacePermission,
  V as updateCustomDomain,
  W as updateCustomDomainRedirect,
  F as updateRoutes,
  B as updateSpaceCustomizationPartial,
  Q as updateSpaceMetadata,
  x as updateSpaceSettings
};

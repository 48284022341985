/* eslint-disable @typescript-eslint/ban-ts-comment */
import { AccessTokenHandler, AccessTokenInterface } from '@typedream/data';
import { useEffect } from 'react';
import { flagsClient, getFrontendFlags } from '@unleash/nextjs';
import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';
import flags from './flags';
import { selectPagesPaths } from './selectors';
import { useStore } from '.';

export const usePagesPaths = (spaceID: string) => useStore(selectPagesPaths(spaceID));

// We are setting feature flag here. This might be duplicate to AuthRequired setup.
// but it's ok.
export const useUnleashFeatureFlag = () => {
  const setFlag = useStore((state) => state.updateFeatureFlags);

  useEffect(() => {
    const accessToken = AccessTokenHandler.getAccessToken();
    const payload = accessToken?.payload as AccessTokenInterface;
    if (!accessToken || !payload?.UID) {
      // user is not logged in
      console.log('user is not logged in');
      return;
    }
    // grab feature flag from unleash
    getFrontendFlags({
      appName: 'default',
      environment: process.env.NODE_ENV,
      context: {
        properties: {
          email: payload.email,
        },
        userId: payload.UID,
      },
    }).then(({ toggles }) => {
      const cl = flagsClient(toggles);
      const unleashFeatureFlags = Object.keys(flags)
        .map((key) =>
          // check if flag is enabled:
          [flags[key], cl.isEnabled(flags[key])]
        )
        .reduce(
          (acc, curr) => ({
            ...acc,
            [curr[0]]: curr[1],
          }),
          {}
        );

      setFlag(unleashFeatureFlags);
    });
    mixpanel.identify(payload?.UID);
    TagManager.dataLayer({
      dataLayer: {
        event: 'logged_in',
        user_data: {
          email: payload?.email,
        },
      },
    });
    window.CommandBar?.boot(payload?.UID);
  }, []);
};

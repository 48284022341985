import {
  StyledDialogModal,
  StyledDialogModalContent,
  StyledDialogOverlay,
} from '@/components/Dialog';
import { X } from '@phosphor-icons/react';
import * as Dialog from '@radix-ui/react-dialog';
import classNames from 'classnames';
import { Dispatch, PropsWithChildren, SetStateAction } from 'react';
import { BaseProps } from '../../types';
import styles from './Popup.module.css';

export interface PopupProps extends PropsWithChildren<BaseProps> {
  open: boolean;
  setopen: Dispatch<SetStateAction<boolean>>;
  width: number | string;
  height: number | string;
  showContentBackground?: boolean;
  showCloseButton?: boolean;
}

function Popup({
  className,
  open,
  setopen,
  width,
  height,
  children,
  showContentBackground = true,
  showCloseButton = true,
  ...contentProps
}: PopupProps) {
  if (typeof document === 'undefined') return null;

  return (
    <Dialog.Root open={open} onOpenChange={setopen}>
      <Dialog.Portal>
        <Dialog.Overlay asChild className={styles.overlay}>
          <StyledDialogOverlay />
        </Dialog.Overlay>
        <Dialog.Content {...contentProps}>
          <StyledDialogModal
            className={styles.content}
            showContentBackground={showContentBackground}
            height={Number.isFinite(height) ? `min(${height}px, 90vh)` : height}
            width={Number.isFinite(width) ? `min(${width}px, 90vw)` : width}
          >
            <StyledDialogModalContent className={classNames(className)}>
              {children}
            </StyledDialogModalContent>
            {showCloseButton && (
              <Dialog.Close
                className="absolute flex bg-background-tertiary border-0 rounded-full p-1 cursor-pointer"
                style={{ top: 20, right: 20 }}
                aria-label="Close"
              >
                <X size={16} className="text-secondary" />
              </Dialog.Close>
            )}
          </StyledDialogModal>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default Popup;

Popup.defaultProps = {
  showContentBackground: true,
  showCloseButton: true,
};

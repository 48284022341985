'use client';

import {
  RootSlice,
  createAIProjectApiSlice,
  createAiApiSlice,
  createAnalyticsApiSlice,
  createAppApiSlice,
  createAssistantApiSlice,
  createAuthSlice,
  createCollectionsApiSlice,
  createContactApiSlice,
  createCouponApiSlice,
  createDocumentsApiSlice,
  createEmailMarketingApiSlice,
  createFormsApiSlice,
  createHistoryApiSlice,
  createLocksApiSlice,
  createPagesApiSlice,
  createPaymentApiSlice,
  createProductsApiSlice,
  createSpacesApiSlice,
  createSubscriptionApiSlice,
  createTemplatesApiSlice,
  createTransclusionApiSlice,
  createAIContextApiSlice,
} from '@typedream/data';
import { generateHooks } from '@typedream/zustand-query';
import create, { GetState, Mutate, SetState, StoreApi } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';
import type { IFeatureFlagSlice, IPublishSettingsPopupSlice } from './App';
import { createFeatureFlagSlice, createPublishSettingsSlice } from './App';

type StoreSlice = RootSlice & IPublishSettingsPopupSlice & IFeatureFlagSlice;

export const useStore = create<
  StoreSlice,
  SetState<StoreSlice>,
  GetState<StoreSlice>,
  Mutate<StoreApi<StoreSlice>, [['zustand/subscribeWithSelector', never]]>
>(
  subscribeWithSelector((set, get, api) => ({
    ...createAuthSlice(),

    // with zustand-query
    ...createPagesApiSlice(api),
    ...createSpacesApiSlice(api),
    ...createLocksApiSlice(api),
    ...createSubscriptionApiSlice(api),
    ...createAnalyticsApiSlice(api),
    ...createTemplatesApiSlice(api),
    ...createFormsApiSlice(api),
    ...createProductsApiSlice(api),
    ...createAppApiSlice(api),
    ...createDocumentsApiSlice(api),
    ...createCollectionsApiSlice(api),
    ...createHistoryApiSlice(api),
    ...createPaymentApiSlice(api),
    ...createTransclusionApiSlice(api),
    ...createContactApiSlice(api),
    ...createCouponApiSlice(api),
    ...createEmailMarketingApiSlice(api),
    ...createAiApiSlice(api),
    ...createAssistantApiSlice(api),
    ...createPublishSettingsSlice(set, get),
    ...createFeatureFlagSlice(set, get),
    ...createAIProjectApiSlice(api),
    ...createAIContextApiSlice(api),
  }))
);

export const {
  useListPagesQuery,
  useGetPageQuery,
  useCreatePageMutation,
  useDeletePageMutation,
  useDuplicatePageMutation,
  useSaveContentMutation,
  useCreateHomePageMutation,
  useUpdatePageCustomizationMutation,
  useUpdatePageInfoMutation,
  useUpdatePageMetadataMutation,
} = generateHooks(useStore, createPagesApiSlice);

export const {
  useAddSpacePermissionMutation,
  useCreateSpaceMutation,
  useCreateSpaceNoPagesMutation,
  useDeleteCustomDomainMutation,
  useDeleteCustomDomainRedirectMutation,
  useGetOrCreatePersonalSpaceQuery,
  useDeleteSpaceMutation,
  useGetSpacePermissionsQuery,
  useGetSpaceQuery,
  useListSpacesQuery,
  usePublishMutation,
  useRemoveSpacePermissionMutation,
  useSaveNavbarMutation,
  useTransferSpacePermissionMutation,
  useUpdateCustomDomainMutation,
  useUpdateCustomDomainRedirectMutation,
  useUpdateRoutesMutation,
  useUpdateSpaceCustomizationPartialMutation,
  useUpdateSpaceMetadataMutation,
  useUpdateSpaceSettingsMutation,
  useCreateUnclaimedSpaceMutation,
  useClaimSpaceMutation,
} = generateHooks(useStore, createSpacesApiSlice);

export const {
  useListLocksQuery,
  useAddLockMutation,
  useCreateLockMutation,
  useDeleteLockMutation,
  useRemoveLockMutation,
  useUpdateLockMutation,
} = generateHooks(useStore, createLocksApiSlice);

export const { useGetSubscriptionQuery, useGetProrateInfoQuery } = generateHooks(
  useStore,
  createSubscriptionApiSlice
);

export const { useGetSharedLinkQuery, useCreateSiteMutation } = generateHooks(
  useStore,
  createAnalyticsApiSlice
);

export const {
  useGetTemplateQuery,
  useGetPublicTemplateQuery,
  useListSectionTemplatesQuery,
  useListTemplatesQuery,
  useListTemplatesPublicQuery,
  useUpdateTemplateUsageMutation,
  useProcessTemplateBeforeUsageMutation,
  useCreateTemplateMutation,
  useUpdateTemplateMutation,
  usePublishTemplateMutation,
  useUnpublishTemplateMutation,
  useDeleteTemplateMutation,
} = generateHooks(useStore, createTemplatesApiSlice);

export const {
  useCreateFormMutation,
  useCreateAndPublishFormMutation,
  useGetFormContentQuery,
  useGetPublishedFormContentQuery,
  useListFormSubmissionsQuery,
  useGetFormSubmissionCountQuery,
  useGetAllFormSubmissionsQuery,
  useUpdateFormContentMutation,
  useListFormsQuery,
  usePublishFormContentMutation,
  useUpdateAndPublishFormContentMutation,
  useRenameFormMutation,
  useDeleteFormMutation,
  useGetFormQuery,
  useUpdateFormMutation,
  useDeleteSubmissionMutation,
} = generateHooks(useStore, createFormsApiSlice);

export const {
  useCreateProductMutation,
  useQueryProductsQuery,
  useGetProductQuery,
  useUpdateProductMutation,
  usePublishProductMutation,
  useGetProductCollectionQuery,
  useUpdateContactEmailMutation,
  useUpdateSenderNameMutation,
  useCountProductsQuery,
  useDeleteProductMutation,
} = generateHooks(useStore, createProductsApiSlice);

export const {
  useCheckStripeConnectionQuery,
  useConnectStripeMutation,
  useDeleteAppMutation,
  useConnectPaypalMutation,
  useCheckPaypalConnectionQuery,
  useCheckOpenAIConnectionQuery,
  useConnectOpenAIMutation,
} = generateHooks(useStore, createAppApiSlice);

export const { useListDocumentsQuery, useUploadDocumentMutation } = generateHooks(
  useStore,
  createDocumentsApiSlice
);

export const {
  useListCollectionItemsQuery,
  useUpdateCollectionItemMutation,
  useCreateCollectionMutation,
  useCreateCollectionItemMutation,
  useListCollectionQuery,
  useGetCollectionItemsCountQuery,
  useGetCollectionQuery,
  useQueryCollectionItemsQuery,
  useUpdateCollectionMutation,
} = generateHooks(useStore, createCollectionsApiSlice);

export const { useGetVersionListQuery, useGetVersionContentQuery } = generateHooks(
  useStore,
  createHistoryApiSlice
);

export const { useListPaymentsQuery, useListAllPaymentsQuery, useCountPaymentsQuery } =
  generateHooks(useStore, createPaymentApiSlice);

export const { useGetAllSyncedContainersQuery, useCreateTransclusionReferenceMutation } =
  generateHooks(useStore, createTransclusionApiSlice);

export const {
  useCountContactsQuery,
  useUploadContactsMutation,
  useListContactsQuery,
  usePreviewFilterContactsQuery,
  usePreviewProductEmailImportContactsQuery,
  useImportProductEmailContactsMutation,
} = generateHooks(useStore, createContactApiSlice);

export const {
  useListEmailCampaignsQuery,
  useListEmailMessagesQuery,
  useGetEmailMessageQuery,
  useCreateEmailMessageMutation,
  useUpdateEmailMessageMutation,
  useProcessEmailMessageMutation,
  useSendPreviewEmailMutation,
  useUpdateEmailSubjectMutation,
  useListEmailMetricsQuery,
  useGetEmailMetricsSummaryQuery,
  useUpdateEmailReplyToMutation,
} = generateHooks(useStore, createEmailMarketingApiSlice);

export const {
  useGenerateAiSiteInfoMutation,
  useGenerateAiSectionsMutation,
  useGenerateAiSectionTemplateDataMutation,
  useSelectAiTemplateKeyMutation,
  useGenerateAIHeadlinesMutation,
  useGenerateAITalkingPointsMutation,
  useGenerateAIKeywordsMutation,
  useGenerateAIPagesMutation,
} = generateHooks(useStore, createAiApiSlice);

export const { useListCouponsQuery } = generateHooks(useStore, createCouponApiSlice);

export const {
  useQueryAssistantsQuery,
  useGetAssistantQuery,
  useCreateNewAssistantMutation,
  useUpdateAssistantMutation,
  useCreateNewGPTMutation,
  useUploadFileGPTMutation,
} = generateHooks(useStore, createAssistantApiSlice);

export const {
  useListAIProjectsQuery,
  useCreateAIProjectMutation,
  useListAIPagesQuery,
  useCreateAIPageMutation,
  useUpdateAIPageMutation,
  useGetAIPageQuery,
  useGetAIProjectQuery,
  useUpdateAIProjectMutation,
  useListAIProjectsByUserIDQuery,
} = generateHooks(useStore, createAIProjectApiSlice);

export const {
  useGetAIContextQuery,
  useCreateAIContextMutation,
  useUpdateAIContextMutation,
  useListAIContextQuery,
  useDeleteAIContextMutation,
} = generateHooks(useStore, createAIContextApiSlice);

// App state hooks
export const useOpenPublishPopupDispatch = () =>
  useStore((state) => state.publishSettings.openPopup);
export const useOpenPublishPopup = () => useStore((state) => state.publishSettings.open);
export const useClosePublishPopupDispatch = () =>
  useStore((state) => state.publishSettings.closePopup);
export const useFeatureFlag = (flag: string) => useStore((state) => !!state.featureFlags[flag]);
export const useFeatureFlags = () => useStore((state) => state.featureFlags);

export * from './hooks';
export * from './selectors';

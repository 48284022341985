import { cn } from '@typedream/ui';
import React from 'react';

export function StyledDialogHeader({ children }: { children: React.ReactNode }) {
  return (
    <div className="border-solid border-0 border-transparent border-b border-b-border-soft pb-5">
      {children}
    </div>
  );
}

export function StyledDialogModalContent({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) {
  return <div className={cn('overflow-x-hidden p-5 flex-1', className)}>{children}</div>;
}

StyledDialogModalContent.defaultProps = {
  className: undefined,
};

export function StyledDialogFooter({ children }: { children: React.ReactNode }) {
  return (
    <div className="border-solid border-0 border-transparent border-t border-t-border-soft p-5 flex-shrink-0">
      {children}
    </div>
  );
}

export function StyledDialogModal({
  children,
  className = '',
  height,
  width,
  showContentBackground = true,
}: {
  children: React.ReactNode;
  className?: string;
  height: number | string;
  width: number | string;
  showContentBackground?: boolean;
}) {
  return (
    <div
      className={cn(
        'fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col max-w-[85vw] max-h-[85vh] overflow-hidden outline-none rounded-2xl p-0 z-10',
        {
          'bg-background': showContentBackground,
        },
        className
      )}
      style={{
        height: Number.isFinite(height) ? `min(${height}px, 90vh)` : height,
        width: Number.isFinite(width) ? `min(${width}px, 90vw)` : width,
      }}
    >
      {children}
    </div>
  );
}
StyledDialogModal.defaultProps = {
  showContentBackground: true,
};

export const StyledDialogOverlay = React.forwardRef<HTMLDivElement>((_, ref) => (
  <div
    ref={ref}
    className="fixed top-0 left-0 bottom-0 right-0 bg-background-overlay backdrop-filter backdrop-blur-[6px] z-10"
  />
));

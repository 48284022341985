import { API as a } from "../../api/api.js";
const d = (e) => async (t) => {
  const { getState: n } = e, { tokenHandlers: { spacePermissionTokenHandler: s, subscriptionTokenHandler: o } } = n();
  return await a.getOrCreateInstance().document().listDocuments(t, s, o);
}, l = (e) => async (t, n, s = !0, o) => {
  const { getState: r } = e, { tokenHandlers: { spacePermissionTokenHandler: c, subscriptionTokenHandler: i } } = r();
  return (await a.getOrCreateInstance().document().upload(n, t, c, i, s, o)).data;
};
export {
  d as listDocuments,
  l as uploadDocument
};

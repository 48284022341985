import { makeApiSliceCreator as c } from "@typedream/zustand-query";
import { listContacts as a, uploadContacts as n, countContacts as r, previewFilterContacts as e, previewProductEmailImportContacts as s, importProductEmailContacts as i } from "./actions.js";
const p = c("contactApi", (t) => ({
  endpoints: (o) => ({
    listContacts: o.query(a(t)),
    uploadContacts: o.mutation(n(t), {
      invalidateQueries: () => [["listContacts", "*", "*", "*"]]
    }),
    countContacts: o.query(r(t)),
    previewFilterContacts: o.query(e(t)),
    previewProductEmailImportContacts: o.query(s(t)),
    importProductEmailContacts: o.mutation(i(t))
  })
}));
export {
  p as createContactApiSlice
};

import { API as r } from "../../api/api.js";
const l = (e) => async (n, a) => {
  const { getState: o } = e, { tokenHandlers: { spacePermissionTokenHandler: s } } = o(), t = await r.getOrCreateInstance().aiContext().get(n, a, s);
  return t == null ? void 0 : t.data;
}, C = (e) => async (n, a, o) => {
  const { getState: s } = e, { tokenHandlers: { spacePermissionTokenHandler: t, subscriptionTokenHandler: c } } = s(), i = await r.getOrCreateInstance().aiContext().create(n, a, o, t, c);
  return i == null ? void 0 : i.data;
}, g = (e) => async (n, a, o) => {
  const { getState: s } = e, { tokenHandlers: { spacePermissionTokenHandler: t } } = s(), c = await r.getOrCreateInstance().aiContext().update(n, a, o, t);
  return c == null ? void 0 : c.data;
}, k = (e) => async (n, a) => {
  const { getState: o } = e, { tokenHandlers: { spacePermissionTokenHandler: s } } = o(), t = await r.getOrCreateInstance().aiContext().list(n, a, s);
  return t == null ? void 0 : t.data;
}, x = (e) => async (n, a) => {
  const { getState: o } = e, { tokenHandlers: { spacePermissionTokenHandler: s } } = o(), t = await r.getOrCreateInstance().aiContext().delete(n, a, s);
  return t == null ? void 0 : t.data;
};
export {
  C as createAIContext,
  x as deleteAIContext,
  l as getAIContext,
  k as listAIContext,
  g as updateAIContext
};

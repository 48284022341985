var o = Object.defineProperty;
var u = (t, r, s) => r in t ? o(t, r, { enumerable: !0, configurable: !0, writable: !0, value: s }) : t[r] = s;
var p = (t, r, s) => (u(t, typeof r != "symbol" ? r + "" : r, s), s);
class i {
  constructor() {
    p(this, "formInputs", []);
    this.formInputs = [];
  }
  process(r) {
    "text" in r || r.type !== "form_input" || this.formInputs.push(r);
  }
}
export {
  i as FormInputExtractor
};

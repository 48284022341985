import { API as l } from "../../api/api.js";
import { App as p } from "../../api/app/app.js";
import { errToString as u } from "../../utils/errors.js";
const m = (o) => async (e, t, r) => {
  var c;
  if (!e)
    return;
  const { getState: n } = o, { tokenHandlers: { spacePermissionTokenHandler: s, subscriptionTokenHandler: a } } = n();
  try {
    return await ((c = new p(l.getOrCreateInstance().api).app(t)) == null ? void 0 : c.getData(e, t, r, s, a));
  } catch (i) {
    throw new Error(u(i));
  }
}, g = (o) => async (e, t, r, n) => {
  var i;
  const { getState: s } = o, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: c } } = s();
  try {
    return await ((i = new p(l.getOrCreateInstance().api).app(n)) == null ? void 0 : i.updateItem(e, t, r, n, a, c));
  } catch (d) {
    throw new Error(u(d));
  }
}, I = (o) => async (e, t) => {
  const { getState: r } = o, { tokenHandlers: n } = r(), { spacePermissionTokenHandler: s, subscriptionTokenHandler: a } = n, c = t;
  return c.space_id = e, delete c.id, delete c.deleted_at, delete c.updated_at, delete c.created_at, await l.getOrCreateInstance().collection().create(c, s, a);
}, w = (o) => async (e) => {
  const { getState: t } = o, { tokenHandlers: r } = t(), { spacePermissionTokenHandler: n, subscriptionTokenHandler: s } = r;
  return await l.getOrCreateInstance().collection().query({}, e, n, s);
}, T = (o) => async (e, t, r) => {
  const { getState: n } = o, { tokenHandlers: s } = n(), { spacePermissionTokenHandler: a, subscriptionTokenHandler: c } = s, i = r;
  return i.collection_id = t, delete i.id, delete i.created_at, await l.getOrCreateInstance().collection().createItem(e, i, a, c);
}, y = (o) => async (e, t) => {
  if (!e || !t)
    throw Error("Either SpaceID, or CollectionID is not provided");
  const { getState: r } = o, { tokenHandlers: { spacePermissionTokenHandler: n, subscriptionTokenHandler: s } } = r();
  return await l.getOrCreateInstance().collection().queryItemsCount(e, t, n, s);
}, S = (o) => async (e, t) => {
  if (!e || !t)
    throw Error("Either SpaceID, or CollectionID is not provided");
  const { getState: r } = o, { tokenHandlers: { spacePermissionTokenHandler: n, subscriptionTokenHandler: s } } = r();
  return await l.getOrCreateInstance().collection().get(t, e, n, s);
}, h = (o) => async (e, t, r, n) => {
  if (!e || !t)
    throw Error("Either SpaceID, or CollectionID is not provided");
  const { getState: s } = o, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: c } } = s();
  return await l.getOrCreateInstance().collection().queryItems(r, e, t, a, c, n);
}, P = (o) => async (e) => {
  if (!e)
    throw Error("Collection is not provided");
  const { getState: t } = o, { tokenHandlers: { spacePermissionTokenHandler: r, subscriptionTokenHandler: n } } = t();
  return await l.getOrCreateInstance().collection().update(e, r, n);
};
export {
  I as createCollection,
  T as createCollectionItem,
  S as getCollection,
  y as getCollectionItemsCount,
  w as listCollection,
  m as listCollectionItems,
  h as queryCollectionItems,
  P as updateCollection,
  g as updateCollectionItem
};

import { POSTHOG_KEY } from '@/constants';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { PropsWithChildren } from 'react';
import cookieCutter from 'cookie-cutter';

if (typeof window !== 'undefined') {
  const phBootsrapCookieKey = 'phBootsrapData';

  // Get the bootstrap cookie data from the middleware
  const bootstrapData = cookieCutter.get(phBootsrapCookieKey);
  let parsedBootstrapData = {};
  if (bootstrapData) {
    parsedBootstrapData = JSON.parse(bootstrapData);
  }

  // Initialize PostHog
  posthog?.init(POSTHOG_KEY, {
    api_host: 'https://app.posthog.com',
    bootstrap: parsedBootstrapData,
    // Enable debug mode in development
    loaded: (ph) => {
      if (process.env.NODE_ENV === 'development' || window.location.host.includes('vercel.app'))
        ph.debug();
    },
  });
}

export default function PosthogWrapperProvider({ children }: PropsWithChildren) {
  return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}

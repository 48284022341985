import { makeApiSliceCreator as t } from "@typedream/zustand-query";
import { checkStripeConnection as o, deleteApp as p, connectStripe as i, checkPaypalConnection as a, connectPaypal as r, checkOpenAIConnection as k, connectOpenAI as C } from "./actions.js";
const s = t("appApi", (n) => ({
  endpoints: (c) => ({
    checkStripeConnection: c.query(o(n)),
    deleteApp: c.mutation(p(n), {
      invalidateQueries: ({ args: e }) => [
        ["checkStripeConnection", e[0].spaceID],
        ["checkPaypalConnection", e[0].spaceID]
      ]
    }),
    connectStripe: c.mutation(i(n), {
      invalidateQueries: ({ args: e }) => [["checkStripeConnection", e[0].spaceID]]
    }),
    checkPaypalConnection: c.query(a(n)),
    connectPaypal: c.mutation(r(n), {
      invalidateQueries: ({ args: e }) => [["checkPaypalConnection", e[0].spaceID]]
    }),
    checkOpenAIConnection: c.query(k(n)),
    connectOpenAI: c.mutation(C(n), {
      invalidateQueries: ({ args: e }) => [["checkOpenAIConnection", e[0].spaceID]]
    })
  })
}));
export {
  s as createAppApiSlice
};

import { API as i } from "../../api/api.js";
import { extractFormInputBlocks as F, generateFormSchema as I } from "../../utils/content/formTransforms.js";
import { MutateContent as j } from "../../utils/content/iterator.js";
const _ = async (e) => {
  const t = { ...e }, s = (await import("./defaults/index.js")).default;
  return Object.entries(s).forEach(([n, { title: a, templates: r }]) => {
    Object.entries(r).forEach(([o, p]) => {
      var l;
      t[n] || (t[n] = {
        title: a,
        templates: {}
      });
      const u = (l = e[n]) == null ? void 0 : l.templates[o];
      t[n] = {
        ...t[n],
        templates: {
          ...t[n].templates,
          [o]: p || u
        }
      };
    });
  }), t;
}, N = () => async () => {
  const e = await i.getOrCreateInstance().template().listSection();
  return await _(e.data);
}, B = (e) => async (t, s, n) => {
  const { getState: a } = e, { tokenHandlers: r } = a(), { spacePermissionTokenHandler: o } = r;
  return (await i.getOrCreateInstance().template().list(t, s, o, n)).data;
}, U = () => async (e) => (await i.getOrCreateInstance().template().listPublic(e)).data, v = (e) => async (t, s) => {
  const { getState: n } = e, { tokenHandlers: a } = n(), { spacePermissionTokenHandler: r } = a;
  return (await i.getOrCreateInstance().template().get(t, s, r)).data;
}, x = (e) => async (t, s) => {
  const { getState: n } = e, { tokenHandlers: a } = n(), { spacePermissionTokenHandler: r } = a;
  return (await i.getOrCreateInstance().template().create(t, s, r)).data;
}, M = (e) => async (t, s, n) => {
  const { getState: a } = e, { tokenHandlers: r } = a(), { spacePermissionTokenHandler: o } = r;
  return (await i.getOrCreateInstance().template().update(t, s, n, o)).data;
}, L = () => async (e) => (await i.getOrCreateInstance().template().getPublic(e)).data, q = (e) => async (t, s) => {
  const { getState: n } = e, { tokenHandlers: a } = n(), { spacePermissionTokenHandler: r } = a;
  return (await i.getOrCreateInstance().template().use(t, s, r)).data;
}, A = (e) => {
  var t, s;
  return Object.keys(((t = e == null ? void 0 : e.form_reference) == null ? void 0 : t.content) || {}).length > 0 || Object.keys(((s = e == null ? void 0 : e.form_reference) == null ? void 0 : s.published_content) || {}).length > 0;
}, G = (e) => async (t, s, n) => {
  var S, h;
  const { getState: a } = e, { templatesApi: r, spacesApi: o, formsApi: p } = a(), { data: u, error: l } = await r.endpoints.getTemplate([t, s]);
  if (l || !u)
    throw console.error(l), l || "Template not found";
  const f = u, k = f.customization;
  if (!k)
    return n;
  const { data: y, error: O } = await o.endpoints.getSpace([t]);
  if (O || !y)
    throw console.error(l), O || "Space not found";
  let w = JSON.parse(JSON.stringify(n));
  if (A(k)) {
    const g = (h = (S = f == null ? void 0 : f.customization) == null ? void 0 : S.form_reference) == null ? void 0 : h.published_content;
    if (!g || Object.keys(g).length === 0)
      return w;
    const H = Object.keys(g).map(async (c) => {
      const { data: m } = await p.endpoints.createAndPublishForm([
        {
          spaceID: t,
          data: g[c].value
        }
      ]);
      if (!m)
        return { [c]: "" };
      const d = g[c].value[0], T = F(d), P = I(T), C = {
        ...m,
        schema: P
      };
      return await p.endpoints.updateForm([t, C]), m ? {
        [c]: m.id
      } : { [c]: "" };
    }), b = await Promise.all(H).then((c) => c.reduce((d, T) => ({
      ...d,
      ...T
    }), {}));
    Object.keys(b).length !== 0 && (w = j(w, [
      (c) => {
        if (!("type" in c) || c.type !== "form")
          return c;
        const m = b[c.form_id], d = JSON.parse(JSON.stringify(c));
        return d.form_id = m, d;
      }
    ]));
  }
  return w;
}, K = (e) => async (t, s) => {
  const { getState: n } = e, { tokenHandlers: a } = n(), { spacePermissionTokenHandler: r } = a;
  return (await i.getOrCreateInstance().template().publish(t, s, r)).data;
}, Q = (e) => async (t, s) => {
  const { getState: n } = e, { tokenHandlers: a } = n(), { spacePermissionTokenHandler: r } = a;
  return (await i.getOrCreateInstance().template().unpublish(t, s, r)).data;
}, R = (e) => async (t, s) => {
  const { getState: n } = e, { tokenHandlers: a } = n(), { spacePermissionTokenHandler: r } = a;
  return (await i.getOrCreateInstance().template().delete(t, s, r)).data;
};
export {
  x as createTemplate,
  R as deleteTemplate,
  L as getPublicTemplate,
  v as getTemplate,
  N as listSectionTemplates,
  B as listTemplates,
  U as listTemplatesPublic,
  G as processTemplateBeforeUsage,
  K as publishTemplate,
  Q as unpublishTemplate,
  M as updateTemplate,
  q as updateTemplateUsage
};

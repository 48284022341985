var n = Object.defineProperty;
var m = (i, e, s) => e in i ? n(i, e, { enumerable: !0, configurable: !0, writable: !0, value: s }) : i[e] = s;
var a = (i, e, s) => (m(i, typeof e != "symbol" ? e + "" : e, s), s);
import { SUBSCRIPTION_PLAN as t } from "../types.js";
function r(i) {
  return i && i.name && i.schema;
}
class _ {
  constructor(e) {
    a(this, "id");
    a(this, "deleted_at");
    a(this, "updated_at");
    a(this, "created_at");
    a(this, "space_id");
    a(this, "schemaVersion", 0);
    a(this, "public_permission");
    a(this, "type");
    a(this, "field");
    function s(d) {
      return d && d.name;
    }
    if (e && s(e)) {
      this.id = e.id, this.space_id = e.space_id, this.deleted_at = new Date().toString(), this.updated_at = e.updated_at, this.created_at = new Date().toString(), this.type = "form", this.public_permission = 2, this.field = {
        name: e.name,
        schemaVersion: this.schemaVersion,
        webhook: e.webhook,
        schema: e.schema,
        form_type: e.form_type
      };
      return;
    }
    if (e && !s(e)) {
      if (this.id = e.id, this.space_id = e.space_id, this.created_at = e.created_at, this.updated_at = e.updated_at, this.deleted_at = e.deleted_at, this.type = "form", this.public_permission = 2, r(e.field))
        this.field = { ...e.field };
      else
        throw Error("Invalid form collection field schema");
      return;
    }
    this.id = "", this.space_id = "", this.deleted_at = new Date().toString(), this.updated_at = new Date().toString(), this.created_at = new Date().toString(), this.public_permission = 2, this.field = {
      name: "",
      schemaVersion: this.schemaVersion,
      webhook: {},
      schema: {}
    }, this.type = "form";
  }
  getCollectionData() {
    return {
      id: this.id,
      space_id: this.space_id,
      created_at: this.created_at,
      updated_at: this.updated_at,
      deleted_at: this.deleted_at,
      public_permission: this.public_permission,
      type: this.type,
      field: this.field
    };
  }
  getFormData() {
    return {
      id: this.id,
      space_id: this.space_id,
      updated_at: this.updated_at || new Date().toString(),
      published_at: new Date().toString(),
      name: this.field.name,
      webhook: this.field.webhook,
      schemaVersion: this.field.schemaVersion,
      schema: this.field.schema,
      form_type: this.field.form_type
    };
  }
  static isSchemaTypeWithOptions(e) {
    return e && e.type !== "text" && e.type !== "number" && e.type !== "email";
  }
  getViewLimit(e) {
    if (this.type === "form") {
      if (this.field.form_type === "email_collection")
        switch (e) {
          case t.GROW:
            return {
              unlimited: !0,
              minimumPlan: t.LAUNCH_15_20
            };
          case t.PRO:
          case t.LAUNCH:
          case t.MINI:
            return {
              unlimited: !0,
              minimumPlan: t.LAUNCH_15_20
            };
          case t.LAUNCH_15_20:
            return {
              limit: 1e3,
              unlimited: !1,
              minimumPlan: t.LAUNCH_15_20
            };
          case t.FREE:
          default:
            return {
              limit: 1,
              unlimited: !1,
              minimumPlan: t.LAUNCH_15_20
            };
        }
      switch (e) {
        case t.GROW:
          return {
            unlimited: !0,
            minimumPlan: t.LAUNCH_15_20
          };
        case t.PRO:
          return {
            unlimited: !0,
            minimumPlan: t.LAUNCH_15_20
          };
        case t.LAUNCH_15_20:
          return {
            limit: 1e3,
            unlimited: !1,
            minimumPlan: t.LAUNCH_15_20
          };
        case t.FREE:
        case t.MINI:
        case t.LAUNCH:
        default:
          return {
            limit: 1,
            unlimited: !1,
            minimumPlan: t.LAUNCH_15_20
          };
      }
    }
    return {
      unlimited: !0,
      minimumPlan: t.FREE
    };
  }
}
export {
  _ as FormCollection
};

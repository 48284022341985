import { NOTION_BLOCK_TYPE as t, BLOCK_TYPE as n, BLOCK_TYPE_UNSUPPORTED as u, CONTAINER_STYLE as T } from "../api/app/notion/types.js";
import { reuploadFileFromURL as h } from "./reuploadFileFromURL.js";
function O(e) {
  var r;
  const c = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g, l = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=|embed\/)?(.+)/g, E = /"https(.*?)"/g;
  let i = 500, d = 500, o = e, a;
  return c.test(e) && (a = "https://player.vimeo.com/video/$1", i = 560, d = 315, o = e.replace(c, a)), l.test(e) && (a = "https://www.youtube.com/embed/$1", i = 560, d = 315, o = e.replace(l, a)), ((e == null ? void 0 : e.includes("iframe")) || (e == null ? void 0 : e.includes("div"))) && (e != null && e.includes("google.com/maps") ? (i = 600, d = 450) : e.includes("typeform.com") || e.includes("tally.so") ? (i = 700, d = 500) : (i = 560, d = 315), o = ((r = e.match(E)) == null ? void 0 : r.toString().replace(/"/g, "")) || e), { convertedURL: o, width: i, height: d };
}
const B = (e, c) => {
  if (c.has_children && (e === t.HEADING_1 || e === t.HEADING_2 || e === t.HEADING_3))
    return n.CONTAINER;
  switch (e) {
    case t.PARAGRAPH:
      return n.PARAGRAPH;
    case t.HEADING_1:
      return n.HEADING1;
    case t.HEADING_2:
      return n.HEADING2;
    case t.HEADING_3:
      return n.HEADING3;
    case t.BULLETED_LIST_ITEM:
      return n.BULLETED_LIST;
    case t.NUMBERED_LIST_ITEM:
      return n.NUMBERED_LIST;
    case t.TO_DO:
      return n.TODO_LIST;
    case t.TOGGLE:
      return n.CONTAINER;
    case t.CODE:
      return n.CODE_BLOCK;
    case t.CHILD_PAGE:
      return n.PARAGRAPH;
    case t.CHILD_DATABASE:
      return n.PARAGRAPH;
    case t.EMBED:
      return n.MEDIA_EMBED;
    case t.IMAGE:
      return n.IMAGE;
    case t.VIDEO:
      return n.MEDIA_EMBED;
    case t.FILE:
      return n.PARAGRAPH;
    case t.PDF:
      return n.MEDIA_EMBED;
    case t.BOOKMARK:
      return n.PARAGRAPH;
    case t.CALLOUT:
      return n.CONTAINER;
    case t.QUOTE:
      return n.BLOCKQUOTE;
    case t.EQUATION:
      return n.CODE_BLOCK;
    case t.DIVIDER:
      return n.DIVIDER;
    case t.TABLE_OF_CONTENTS:
      return u;
    case t.BREADCRUMB:
      return u;
    case t.COLUMN:
      return n.COLUMN_ITEM;
    case t.COLUMN_LIST:
      return n.COLUMN;
    case t.LINK_PREVIEW:
      return n.PARAGRAPH;
    case t.SYNCED_BLOCK:
      return n.CONTAINER;
    case t.TEMPLATE:
      return u;
    case t.LINK_TO_PAGE:
      return u;
    case t.TABLE:
      return u;
    case t.TABLE_ROW:
      return u;
    case t.UNSUPPORTED:
      return u;
    default:
      return n.PARAGRAPH;
  }
}, f = (e) => ({
  id: e(),
  type: "p",
  children: [
    {
      text: "Unsupported block type",
      color: "gray"
    }
  ]
}), C = (e) => [
  {
    children: [
      {
        id: e(),
        type: n.SPACER,
        height: 70,
        children: [
          {
            text: ""
          }
        ]
      },
      {
        id: e(),
        type: n.PARAGRAPH,
        children: [
          {
            text: ""
          }
        ]
      }
    ]
  }
], L = (e) => e === "default", y = (e, c, l, E) => {
  const i = E == null ? void 0 : E.includes("background"), d = (E == null ? void 0 : E.split("_")[0]) || void 0, o = e.annotations.color.includes("background"), a = e.annotations.color.split("_")[0];
  let r, s;
  c !== t.CALLOUT && (o && !L(a) ? s = a : i && (s = d)), !o && !L(a) ? r = a : i || (r = d);
  const _ = Boolean(e.href), p = {
    id: l(),
    text: e.plain_text || "",
    bold: e.annotations.bold || void 0,
    italic: e.annotations.italic || void 0,
    strikethrough: e.annotations.strikethrough || void 0,
    underline: e.annotations.underline || void 0,
    code: e.annotations.code || e.type === "equation" || void 0,
    color: r && !L(r) ? r : void 0,
    background: s && !L(s) ? s : void 0,
    styled: Boolean(r && !L(r) || s && !L(s))
  };
  return _ ? {
    type: n.LINK,
    url: e.href || "",
    open_in_new_tab: !1,
    id: l(),
    children: [p]
  } : p;
}, U = async (e, c, l, E, i, d) => {
  var p, D;
  const o = c.type, a = c[c.type].type;
  let r = {};
  e === n.CONTAINER && (r = {
    ...r,
    style: T.CARD
  });
  let s = (D = (p = c[o]) == null ? void 0 : p[a]) == null ? void 0 : D.url;
  const _ = "";
  if (a === t.FILE && s)
    try {
      s = await h(s, {
        spaceID: l,
        apiBaseURL: E,
        spacePermissionTokenHandler: i,
        subscriptionTokenHandler: d
      });
    } catch (A) {
      console.error("Fail `getAdditionalProps` when importing Notion content", A);
    }
  switch (o) {
    case t.IMAGE:
    case t.PDF:
      r = {
        ...r,
        url: s
      };
      break;
    case t.VIDEO: {
      const { convertedURL: A } = O(s), I = _.includes("video");
      r = {
        ...r,
        url: I ? void 0 : A,
        html: I ? `<video playsinline="" controls="" preload="metadata" src=${s} style="display: block; width: 100%; height: 100%;object-fit: contain;"></video>` : void 0,
        width: 560,
        height: 315,
        keep_aspect_ratio: !0
      };
      break;
    }
    case t.EMBED: {
      const { convertedURL: A, height: I, width: R } = O(c[o].url);
      r = {
        ...r,
        url: A,
        width: R,
        height: I
      };
      break;
    }
    case t.CALLOUT: {
      const { color: A } = c[o], R = A.includes("background") ? A.split("_")[0] : void 0;
      r = {
        ...r,
        background: R
      };
      break;
    }
  }
  return r;
};
export {
  B as convertNotionBlockTypeToTdType,
  y as convertNotionTextToTdTextLeaf,
  U as getAdditionalProps,
  C as initialValueEmpty,
  f as unsupportedBlockPlaceholder
};

var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"442f257fcf3647e25df1cee35b2d881d2cc8c2e9"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

import('@sentry/nextjs').then((s) =>
  s.init({
    dsn: SENTRY_DSN || 'https://6c3ebca9d5aa4b4aa319580f183eee16@o1066317.ingest.sentry.io/6260637',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
    tracesSampler: (samplingContext) => {
      if (samplingContext.page === '/ai') {
        return 1;
      }
      return 0.1;
    },
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0.1,

    integrations: [new s.Replay()],
  })
);

var u = Object.defineProperty;
var p = (s, t, i) => t in s ? u(s, t, { enumerable: !0, configurable: !0, writable: !0, value: i }) : s[t] = i;
var o = (s, t, i) => (p(s, typeof t != "symbol" ? t + "" : t, i), i);
import { API as k } from "../../api/api.js";
import { SubscriptionToken as h } from "./SubscriptionToken.js";
import { SUBSCRIPTION_STORAGE_KEY as c } from "../../constants/constants.js";
const r = {
  initial: 1,
  fetching: 2,
  errorRetry: 3,
  errorFatal: 4,
  ready: 5
};
class a {
  constructor(t) {
    o(this, "tokenFetchingState", r.initial);
    o(this, "spacePermissionTokenHandler");
    o(this, "fetchTokenResp");
    this.spacePermissionTokenHandler = t;
  }
  storeTokens(t) {
    var n;
    const e = {
      ...a.getAllSubscriptions(),
      [t.space_id]: t.token
    };
    try {
      (n = window == null ? void 0 : window.localStorage) == null || n.setItem(c, JSON.stringify(e));
    } catch {
    }
    this.tokenFetchingState = r.initial;
  }
  static getAllSubscriptions() {
    var i;
    const t = (i = window == null ? void 0 : window.localStorage) == null ? void 0 : i.getItem(c);
    return t ? JSON.parse(t) : null;
  }
  async getSubscription(t) {
    const i = a.getAllSubscriptions();
    let e = i ? i[t] : null, n = null;
    if (e && e.length > 0 && (n = new h(e)), e === void 0 || n === null || n === void 0 || n.getExpiration() < new Date().getTime() / 1e3)
      try {
        e = (await this.refreshSubscriptionToken(t)).token;
      } catch {
        return null;
      }
    return e ? new h(e) : null;
  }
  async refreshSubscriptionToken(t) {
    return new Promise((i, e) => {
      const n = () => {
        switch (this.tokenFetchingState) {
          case r.initial:
            this.refreshSubscriptionTokenAPI(t);
            break;
          case r.ready:
            if (this.fetchTokenResp) {
              i(this.fetchTokenResp), this.tokenFetchingState = r.initial;
              return;
            }
            break;
          case r.errorRetry:
            this.tokenFetchingState = r.initial, e();
            return;
          case r.errorFatal:
            e();
            return;
        }
        setTimeout(n, 0);
      };
      n();
    });
  }
  async refreshSubscriptionTokenAPI(t) {
    var i;
    this.tokenFetchingState = r.fetching, this.fetchTokenResp = null;
    try {
      const e = await k.getOrCreateInstance().subscription().get(t, this.spacePermissionTokenHandler);
      return this.storeTokens(e), this.tokenFetchingState = r.ready, this.fetchTokenResp = e, e;
    } catch (e) {
      (i = e.msg) != null && i.includes("not valid") ? this.tokenFetchingState = r.errorFatal : this.tokenFetchingState = r.errorRetry;
    }
    return null;
  }
  static removeTokens() {
    var t;
    try {
      (t = window == null ? void 0 : window.localStorage) == null || t.removeItem(c);
    } catch {
    }
  }
}
export {
  a as STokenHandler
};

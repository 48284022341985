import { SUBSCRIPTION_PLAN_CYCLE } from '@/types';
import { SUBSCRIPTION_PLAN } from '@typedream/data';

export default {
  AIAssistant: 'ai-assistant-v1',
  EmailMarketing: 'email-marketing-v1',
  Helphub: 'helphub',
  TypedreamCMS: 'typedream-cms',
  AudienceFilter: 'audience-filter-v1',
  PricingLaunchGrow: 'pricing-launch-grow',
  PaypalProducts: 'paypal_products',
  CouponCode: 'coupon-code',
  GPTAssistant: 'assistant-gpt-v0',
  AIExportFigma: 'ai-export-figma',
  PriceABTest: 'price-ab',
  OnboardingAnyscale: 'onboarding-anyscale',
  PermanentRedirects: 'permanent-redirect',
  DreamEditor: 'dream-editor',
  AIWebsiteBuilderV2: 'ai-web-v2',
  TemplateMarketplace: 'template-marketplace',
};

export type IPriceABTestPayload = {
  price: {
    [key in SUBSCRIPTION_PLAN]: {
      [cycle in SUBSCRIPTION_PLAN_CYCLE]: number;
    };
  };
};

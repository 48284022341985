import { makeApiSliceCreator as t } from "@typedream/zustand-query";
import { listEmailCampaigns as i, listEmailMessages as m, getEmailMessage as l, createEmailMessage as E, updateEmailMessage as g, processEmailMessage as M, sendPreviewEmail as n, updateEmailSubject as o, updateEmailReplyTo as r, listEmailMetrics as u, getEmailMetricsSummary as c } from "./actions.js";
const v = t("emailMarketingApi", (e) => ({
  endpoints: (a) => ({
    listEmailCampaigns: a.query(i(e)),
    listEmailMessages: a.query(m(e)),
    getEmailMessage: a.query(l(e)),
    createEmailMessage: a.mutation(E(e), {
      invalidateQueries: ({ args: s }) => [["listEmailMessages", s[1], "*"]]
    }),
    updateEmailMessage: a.mutation(g(e), {
      invalidateQueries: ({ args: s }) => [["getEmailMessage", s[1], s[2]]]
    }),
    processEmailMessage: a.mutation(M(e), {}),
    sendPreviewEmail: a.mutation(n(e), {}),
    updateEmailSubject: a.mutation(o(e), {
      invalidateQueries: ({ args: s }) => [["getEmailMessage", s[2], s[1]]]
    }),
    updateEmailReplyTo: a.mutation(r(e), {
      invalidateQueries: ({ args: s }) => [["getEmailMessage", s[2], s[1]]]
    }),
    listEmailMetrics: a.query(u(e)),
    getEmailMetricsSummary: a.query(c(e))
  })
}));
export {
  v as createEmailMarketingApiSlice
};

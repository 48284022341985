import { produce as w } from "immer";
import { generateSlug as h } from "random-word-slugs";
import { API as f } from "../../api/api.js";
import { FormCollection as _ } from "../../class/form.js";
const T = (r) => async (t, e) => {
  var p;
  if (!t || !e)
    return;
  const { getState: a } = r, { spacesApi: n, tokenHandlers: o } = a(), { spacePermissionTokenHandler: s, subscriptionTokenHandler: i } = o;
  try {
    await f.getOrCreateInstance().collection().delete(e, t, s, i);
  } catch (l) {
    console.error(l);
  }
  let c = n.endpoints.getSpace.get(t).data;
  if (!c) {
    const { data: l } = await n.endpoints.getSpace([t]);
    l && (c = l);
  }
  const d = (p = c == null ? void 0 : c.customization) == null ? void 0 : p.form_reference;
  if (!d)
    return;
  const m = w(d.content || {}, (l) => {
    l[e] && delete l[e];
  }), u = w(d.published_content || {}, (l) => {
    l[e] && delete l[e];
  });
  await n.endpoints.updateSpaceCustomizationPartial([
    {
      spaceID: t,
      customizationUpdateRequests: [
        {
          customization_value: {
            value: m,
            updated_at: new Date().toISOString()
          },
          customization_path: ["form_reference", "content", e],
          customization_delete: !0
        }
      ]
    }
  ]), await n.endpoints.updateSpaceCustomizationPartial([
    {
      spaceID: t,
      customizationUpdateRequests: [
        {
          customization_value: {
            value: u,
            updated_at: new Date().toISOString()
          },
          customization_path: ["form_reference", "published_content", e],
          customization_delete: !0
        }
      ]
    }
  ]);
}, P = (r) => async (t, e) => {
  if (!t || !e)
    throw Error("Either SpaceID, newForm is not provided");
  const { getState: a } = r, { tokenHandlers: n } = a(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: s } = n, i = new _(e), c = await f.getOrCreateInstance().collection().update(i, o, s);
  return new _(c).getFormData();
}, v = (r) => async (t, e, a, n) => {
  if (!t || !e)
    throw Error("Either SpaceID, or FormID is not provided");
  const { getState: o } = r, { tokenHandlers: s } = o(), { spacePermissionTokenHandler: i, subscriptionTokenHandler: c } = s;
  return (await f.getOrCreateInstance().collection().queryItems({
    limit: a,
    offset: n
  }, t, e, i, c)).results;
}, O = (r) => async (t, e) => {
  if (!t || !e)
    throw Error("Either SpaceID, or FormID is not provided");
  const { getState: a } = r, { tokenHandlers: n } = a(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: s } = n;
  return await f.getOrCreateInstance().collection().queryItemsCount(t, e, o, s);
}, E = (r) => async (t, e, a) => {
  if (!t || !e || !a)
    throw Error("Either SpaceID, FormID, or NewName is not provided");
  const { getState: n } = r, { tokenHandlers: o } = n(), { spacePermissionTokenHandler: s, subscriptionTokenHandler: i } = o, c = await f.getOrCreateInstance().collection().updateField(t, e, ["name"], a, s, i);
  return new _(c).getFormData();
}, I = (r) => async (t, e) => {
  if (!t)
    return [];
  const { getState: a } = r, { tokenHandlers: n } = a(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: s } = n;
  return await f.getOrCreateInstance().collection().queryAllItems(t, e, o, s);
}, A = (r) => async (t) => {
  var m;
  if (!t)
    return [];
  const { getState: e } = r, { spacesApi: a, tokenHandlers: n } = e(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: s } = n;
  let i = a.endpoints.getSpace.get(t).data;
  if (!i) {
    const { data: u } = await a.endpoints.getSpace([t]);
    u && (i = u);
  }
  const c = (m = i == null ? void 0 : i.customization) == null ? void 0 : m.form_reference, d = await f.getOrCreateInstance().collection().query({}, t, o, s);
  if (d) {
    const u = [];
    return d.filter((p) => p.type === "form").forEach((p) => {
      var l, S;
      try {
        const g = new _(p);
        u.push({
          ...g.getFormData(),
          published_at: ((S = (l = c == null ? void 0 : c.published_content) == null ? void 0 : l[p.id]) == null ? void 0 : S.updated_at) || ""
        });
      } catch {
        u.push({
          id: p.id,
          published_at: "",
          updated_at: "",
          schema: {},
          space_id: p.space_id
        });
      }
    }), u;
  }
  return [];
}, q = (r) => async (t, e) => {
  var p, l, S;
  if (!t)
    return null;
  const { getState: a } = r, { spacesApi: n, tokenHandlers: o } = a(), { spacePermissionTokenHandler: s, subscriptionTokenHandler: i } = o, c = await f.getOrCreateInstance().collection().get(e, t, s, i), d = new _(c);
  let m = n.endpoints.getSpace.get(t).data;
  if (!m) {
    const { data: g } = await n.endpoints.getSpace([t]);
    g && (m = g);
  }
  const u = (p = m == null ? void 0 : m.customization) == null ? void 0 : p.form_reference;
  if (u) {
    const g = ((l = u.content) == null ? void 0 : l[e]) || { updated_at: "" }, F = ((S = u.published_content) == null ? void 0 : S[e]) || { updated_at: "" };
    return {
      ...d.getFormData(),
      published_at: F.updated_at,
      updated_at: g.updated_at
    };
  }
  return null;
}, R = (r) => async (t, e) => {
  var s, i, c;
  if (!t || !e)
    return [];
  const { getState: a } = r, { spacesApi: n } = a();
  let o = n.endpoints.getSpace.get(t).data;
  if (!o) {
    const { data: d } = await n.endpoints.getSpace([t]);
    d && (o = d);
  }
  return ((c = (i = (s = o == null ? void 0 : o.customization) == null ? void 0 : s.form_reference) == null ? void 0 : i.content) == null ? void 0 : c[e].value) || [];
}, U = (r) => async (t, e) => {
  var s, i, c;
  if (!t || !e)
    return [];
  const { getState: a } = r, { spacesApi: n } = a();
  let o = n.endpoints.getSpace.get(t).data;
  if (!o) {
    const { data: d } = await n.endpoints.getSpace([t]);
    d && (o = d);
  }
  return ((c = (i = (s = o == null ? void 0 : o.customization) == null ? void 0 : s.form_reference) == null ? void 0 : i.published_content) == null ? void 0 : c[e].value) || [];
}, C = (r) => async (t) => {
  const { formID: e, data: a } = t, { getState: n } = r, { spacesApi: o } = n();
  return o.endpoints.updateSpaceCustomizationPartial([
    {
      spaceID: t.spaceID,
      customizationUpdateRequests: [
        {
          customization_value: {
            value: a,
            updated_at: new Date().toISOString()
          },
          customization_path: ["form_reference", "content", e]
        }
      ]
    }
  ]), a;
}, D = (r) => async (t, e, a) => {
  const n = new _();
  if (!t)
    return n.getFormData();
  const { getState: o } = r, { tokenHandlers: s } = o(), { spacePermissionTokenHandler: i, subscriptionTokenHandler: c } = s;
  n.space_id = t, n.field.name = e || h(), a && (n.field.form_type = a);
  const d = n;
  delete d.id, delete d.deleted_at, delete d.updated_at, delete d.created_at;
  const m = await f.getOrCreateInstance().collection().create(d, i, c), u = new _(m);
  if (!await C(r)({
    spaceID: t,
    formID: m.id,
    data: []
  }))
    throw Error("Error updating form content after creating form");
  return u.getFormData();
}, N = (r) => async (t) => {
  const { formID: e, spaceID: a } = t, { getState: n } = r, { spacesApi: o, formsApi: s } = n(), { data: i, error: c } = await s.endpoints.getFormContent([
    a,
    e
  ]);
  if (i && !c) {
    const d = await o.endpoints.updateSpaceCustomizationPartial([
      {
        spaceID: t.spaceID,
        customizationUpdateRequests: [
          {
            customization_value: {
              value: i,
              updated_at: new Date().toISOString()
            },
            customization_path: ["form_reference", "published_content", e]
          }
        ]
      }
    ]);
    if (!d.data || d.error)
      throw Error(d.error);
    return i;
  }
  return [];
}, k = (r) => async (t) => {
  const { formID: e, data: a } = t, { getState: n } = r, { spacesApi: o } = n(), s = await o.endpoints.updateSpaceCustomizationPartial([
    {
      spaceID: t.spaceID,
      customizationUpdateRequests: [
        {
          customization_value: {
            value: a,
            updated_at: new Date().toISOString()
          },
          customization_path: ["form_reference", "content", e]
        },
        {
          customization_value: {
            value: a,
            updated_at: new Date().toISOString()
          },
          customization_path: ["form_reference", "published_content", e]
        }
      ]
    }
  ]);
  if (!s.data || s.error)
    throw Error(s.error);
  return a;
}, x = (r) => async (t) => {
  const { spaceID: e, data: a, formName: n, formType: o } = t, s = new _();
  if (!e)
    return s.getFormData();
  const { getState: i } = r, { tokenHandlers: c } = i(), { spacePermissionTokenHandler: d, subscriptionTokenHandler: m } = c, u = w(s.getCollectionData(), (g) => {
    g.space_id = e, g.field.name = n || h(), o && (g.field.form_type = o), delete g.id, delete g.deleted_at, delete g.updated_at, delete g.created_at;
  }), p = await f.getOrCreateInstance().collection().create(u, d, m), l = new _(p);
  if (!await k(r)({
    spaceID: e,
    formID: p.id,
    data: a
  }))
    throw Error("Error updating form content after creating form");
  return l.getFormData();
}, L = (r) => async (t) => {
  const { ids: e, formID: a, spaceID: n } = t, { getState: o } = r, { tokenHandlers: s } = o(), { spacePermissionTokenHandler: i, subscriptionTokenHandler: c } = s;
  return await f.getOrCreateInstance().collection().deleteItems(e, n, a, i, c);
};
export {
  x as createAndPublishForm,
  D as createForm,
  T as deleteForm,
  L as deleteSubmissions,
  I as getAllFormSubmissions,
  q as getForm,
  R as getFormContent,
  v as getFormSubmissions,
  O as getFormSubmissionsCount,
  U as getPublishedFormContent,
  A as listForms,
  N as publishFormContent,
  E as renameForm,
  k as updateAndPublishFormContent,
  P as updateForm,
  C as updateFormContent
};

import { makeApiSliceCreator as l } from "@typedream/zustand-query";
import { listCollectionItems as i, updateCollectionItem as n, createCollectionItem as c, listCollection as C, createCollection as m, getCollectionItemsCount as s, getCollection as u, queryCollectionItems as I, updateCollection as a } from "./actions.js";
const y = l("collectionsApi", (t) => ({
  endpoints: (o) => ({
    listCollectionItems: o.query(i(t)),
    updateCollectionItem: o.mutation(n(t), {
      invalidateQueries: ({ args: e }) => [["listCollectionItems", e[0], "*", "*"]]
    }),
    createCollectionItem: o.mutation(c(t), {
      invalidateQueries: ({ args: e }) => [["listCollectionItems", e[0], "*", "*"]]
    }),
    listCollection: o.query(C(t)),
    createCollection: o.mutation(m(t), {
      invalidateQueries: ({ args: e }) => [["listCollection", e[0]]]
    }),
    getCollectionItemsCount: o.query(s(t)),
    getCollection: o.query(u(t)),
    queryCollectionItems: o.query(I(t)),
    updateCollection: o.mutation(a(t), {
      invalidateQueries: ({ args: e }) => [
        ["getCollection", e[0].space_id, e[0].id],
        ["queryCollectionItems", e[0].space_id, e[0].id, "*"],
        ["queryCollectionItems", e[0].space_id, e[0].id, "*", "*"]
      ]
    })
  })
}));
export {
  y as createCollectionsApiSlice
};

var f = Object.defineProperty;
var p = (o, r, i) => r in o ? f(o, r, { enumerable: !0, configurable: !0, writable: !0, value: i }) : o[r] = i;
var d = (o, r, i) => (p(o, typeof r != "symbol" ? r + "" : r, i), i);
import { JwtToken as u } from "./JwtToken.js";
import { SPACE_ACTION as s, SUBSCRIPTION_PLAN as e } from "../../types.js";
import { SUBSCRIPTION_PLAN_MAX_COLLABORATORS as I } from "../../constants/constants.js";
class k extends u {
  constructor(i) {
    super(i);
    d(this, "payload");
    this.payload = this.decodePayload();
  }
  decodePayload() {
    return super.decodePayload();
  }
  getProductID() {
    return this.payload.product_id;
  }
  getSubscriptionID() {
    return this.payload.subscription_id;
  }
  getSpaceID() {
    return this.payload.space_id;
  }
  getStatus() {
    return this.payload.status;
  }
  getPlan(i) {
    return i[this.getProductID()];
  }
  getMetadata() {
    return this.payload.metadata;
  }
  isAllowed(i, c, n) {
    const a = this.getPlan(c);
    let t = !0, l;
    switch (i) {
      case s.SendEmail:
        a !== e.GROW && a !== e.LAUNCH_15_20 && (t = !1);
        break;
      case s.UpdateAffiliate:
        (a === e.FREE || a === e.MINI) && (t = !1);
        break;
      case s.UpdateCustomDomain:
        (a === e.FREE || a === e.MINI) && (t = !1);
        break;
      case s.UpdateCustomization:
        (a === e.FREE || a === e.MINI) && (t = !1);
        break;
      case s.UpdateMetadata:
        (a === e.FREE || a === e.MINI) && (t = !1);
        break;
      case s.UpdateSubdomain:
        (a === e.FREE || a === e.MINI) && (t = !1);
        break;
      case s.InviteMember:
        (a === e.FREE || a === e.MINI) && (t = !1), l = parseInt(n, 10), (a === e.LAUNCH_15_20 || a === e.GROW) && l > I[a] && (t = !1);
        break;
      case s.UpdateAnalytics:
        a === e.FREE && (t = !1);
        break;
      case s.ReadBasicAnalytics:
        a === e.FREE && (t = !1);
        break;
      case s.ReadAdvancedAnalytics:
        a === e.FREE && (t = !1);
        break;
      case s.CreateLock:
        a !== e.PRO && a !== e.GROW && (t = !1);
        break;
      case s.PublishUnlimitedPages:
        (a === e.FREE || a === e.MINI) && (t = !1);
        break;
      case s.Collection:
      case s.EmailCollectionCard:
      case s.LinkInBioAppearance:
      default:
        t = !0;
    }
    return t;
  }
}
export {
  k as SubscriptionToken
};

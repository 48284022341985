import { API as c } from "../../api/api.js";
const H = (e) => async (t, a) => {
  if (!t)
    return;
  const { getState: s } = e, { tokenHandlers: r } = s(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: n } = r;
  return (await c.getOrCreateInstance().assistant().query(t, a, o, n)).data;
}, g = (e) => async (t, a) => {
  if (!t)
    return;
  const { getState: s } = e, { tokenHandlers: r } = s(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: n } = r;
  return (await c.getOrCreateInstance().assistant().get(t, a, o, n)).data;
}, T = (e) => async ({ spaceID: t, assistantID: a, name: s, slug: r }) => {
  if (!t)
    return;
  const { getState: o } = e, { tokenHandlers: n } = o(), { spacePermissionTokenHandler: i, subscriptionTokenHandler: d } = n;
  return (await c.getOrCreateInstance().assistant().create(t, {
    assistant_id: a,
    assistant_name: s,
    product_slug: r,
    assistant_description: "",
    cover_image: ""
  }, i, d)).data;
}, m = (e) => async ({ spaceID: t, assistantID: a, data: s }) => {
  if (!t)
    return;
  const { getState: r } = e, { tokenHandlers: o } = r(), { spacePermissionTokenHandler: n, subscriptionTokenHandler: i } = o;
  return (await c.getOrCreateInstance().assistant().update(t, a, s, n, i)).data;
}, P = (e) => async ({ model: t, instructions: a, name: s, tools: r, file_ids: o, spaceID: n }) => {
  if (!n)
    return;
  const { getState: i } = e, { tokenHandlers: d } = i(), { spacePermissionTokenHandler: p, subscriptionTokenHandler: u } = d;
  return (await c.getOrCreateInstance().assistant().createNewGPT(n, {
    model: t,
    instructions: a,
    name: s,
    tools: r,
    file_ids: o
  }, p, u)).data;
}, w = (e) => async ({ file: t, purpose: a, spaceID: s }) => {
  if (!s)
    return;
  const { getState: r } = e, { tokenHandlers: o } = r(), { spacePermissionTokenHandler: n, subscriptionTokenHandler: i } = o;
  return (await c.getOrCreateInstance().assistant().upload(t, a, s, n, i)).data;
}, b = (e) => async ({ spaceID: t, assistantID: a }) => {
  if (!t)
    return;
  const { getState: s } = e, { tokenHandlers: r } = s(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: n } = r;
  return (await c.getOrCreateInstance().assistant().publish(t, a, o, n)).data;
};
export {
  T as createNewAssistant,
  P as createNewGPT,
  g as getAssistant,
  b as publishAssistant,
  H as queryAssistants,
  m as updateAssistant,
  w as uploadFileGPT
};

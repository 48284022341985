var e = Object.defineProperty;
var p = (i, t, r) => t in i ? e(i, t, { enumerable: !0, configurable: !0, writable: !0, value: r }) : i[t] = r;
var n = (i, t, r) => (p(i, typeof t != "symbol" ? t + "" : t, r), r);
import { ICollectionIntegration as o } from "../../types.js";
import { CmsAPP as s } from "./cms/app.js";
import { NotionAPP as a } from "./notion/app.js";
class f {
  constructor(t) {
    n(this, "api");
    this.api = t;
  }
  app(t) {
    if (!t)
      return null;
    const { integration: r } = t;
    switch (r) {
      case o.NOTION:
        return new a(this.api);
      case o.TYPEDREAM_CMS:
        return new s(this.api);
      default:
        return null;
    }
  }
}
export {
  f as App
};

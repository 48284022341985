import { makeApiSliceCreator as A } from "@typedream/zustand-query";
import { listAIProjects as r, listAIProjectsByUserID as a, getAIProject as I, createAIProject as P, updateAIProject as j, listAIPages as n, getAIPage as g, createAIPage as u, updateAIPage as l } from "./actions.js";
const y = A("aiProjectApi", (e) => ({
  endpoints: (t) => ({
    listAIProjects: t.query(r(e)),
    listAIProjectsByUserID: t.query(a()),
    getAIProject: t.query(I(e)),
    createAIProject: t.mutation(P(e), {
      invalidateQueries: () => [["listAIProjects"], ["listAIProjectsByUserID"]]
    }),
    updateAIProject: t.mutation(j(e), {
      onSuccess: ({ args: o }) => {
        const { aiProjectApi: c } = e.getState();
        c.invalidateQueries(["listAIProjects", o[0].space_id], ["getAIProject", o[0].id, o[0].space_id]);
      }
    }),
    listAIPages: t.query(n(e)),
    getAIPage: t.query(g(e)),
    createAIPage: t.mutation(u(e), {
      invalidateQueries: () => [["listAIPages"]]
    }),
    updateAIPage: t.mutation(l(e), {
      onSuccess: ({ args: o, data: c }) => {
        const { aiProjectApi: s } = e.getState();
        c && s.endpoints.getAIPage.get(o[0].id, o[1]).updateData((i) => ({
          ...i,
          ...c
        })), s.invalidateQueries(["listAIPages", o[0].ai_project_id, o[1]]);
      }
    })
  })
}));
export {
  y as createAIProjectApiSlice
};

import { makeApiSliceCreator as t } from "@typedream/zustand-query";
import { generateAiSiteInfo as a, generateAiSections as n, generateAiSectionTemplateData as i, selectAiTemplateKey as o, generateAIHeadlines as A, generateAITalkingPoints as g, generateAIKeywords as m, generateAIPages as r } from "./actions.js";
const I = t("ai", (s) => ({
  endpoints: (e) => ({
    generateAiSiteInfo: e.mutation(a()),
    generateAiSections: e.mutation(n()),
    generateAiSectionTemplateData: e.mutation(i()),
    selectAiTemplateKey: e.mutation(o()),
    generateAIHeadlines: e.mutation(A()),
    generateAITalkingPoints: e.mutation(g()),
    generateAIKeywords: e.mutation(m()),
    generateAIPages: e.mutation(r())
  })
}));
export {
  I as createAiApiSlice
};

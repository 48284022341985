import { produce as m } from "immer";
import { API as c } from "../../api/api.js";
const u = (n) => async (s) => {
  const { getState: e } = n, { tokenHandlers: { spacePermissionTokenHandler: t, subscriptionTokenHandler: a } } = e();
  return (await c.getOrCreateInstance().emailMarketing().list(s, t, a)).data;
}, M = (n) => async (s) => {
  const { getState: e } = n, { tokenHandlers: { spacePermissionTokenHandler: t, subscriptionTokenHandler: a } } = e();
  return (await c.getOrCreateInstance().emailMarketing().listEmailMessages(s, t, a)).data;
}, H = (n) => async (s, e) => {
  const { getState: t } = n, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: r } } = t();
  return (await c.getOrCreateInstance().emailMarketing().getEmailMessage(e, s, a, r)).data;
}, E = (n) => async (s, e) => {
  const { getState: t } = n, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: r } } = t();
  return (await c.getOrCreateInstance().emailMarketing().createEmailMessage(s, e, a, r)).data;
}, w = (n) => async (s, e, t) => {
  const { getState: a } = n, { tokenHandlers: { spacePermissionTokenHandler: r, subscriptionTokenHandler: o } } = a();
  return (await c.getOrCreateInstance().emailMarketing().updateEmailMessage(s, t, e, r, o)).data;
}, T = (n) => async (s, e, t) => {
  const { getState: a } = n, { tokenHandlers: { spacePermissionTokenHandler: r, subscriptionTokenHandler: o } } = a();
  let i = await a().emailMarketingApi.endpoints.getEmailMessage.get(t, e).data;
  if (!i && (i = await a().emailMarketingApi.endpoints.getEmailMessage([t, e]).unwrap(), !i))
    throw new Error("Email message not found");
  const l = m(i, (d) => {
    d.document.subject = s;
  });
  return (await c.getOrCreateInstance().emailMarketing().updateEmailMessage(l.document, e, t, r, o)).data;
}, y = (n) => async (s, e, t) => {
  const { getState: a } = n, { tokenHandlers: { spacePermissionTokenHandler: r, subscriptionTokenHandler: o } } = a();
  let i = await a().emailMarketingApi.endpoints.getEmailMessage.get(t, e).data;
  if (!i && (i = await a().emailMarketingApi.endpoints.getEmailMessage([t, e]).unwrap(), !i))
    throw new Error("Email message not found");
  const l = m(i, (d) => {
    d.document.reply_to = s;
  });
  return (await c.getOrCreateInstance().emailMarketing().updateEmailMessage(l.document, e, t, r, o)).data;
}, P = (n) => async (s, e) => {
  const { getState: t } = n, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: r } } = t();
  await c.getOrCreateInstance().emailMarketing().processEmailCampaign(s, e, a, r);
}, S = (n) => async (s, e, t, a, r) => {
  const { getState: o } = n, { tokenHandlers: { spacePermissionTokenHandler: i, subscriptionTokenHandler: l } } = o();
  await c.getOrCreateInstance().emailMarketing().sendPreviewEmail({
    collection_id: e,
    collection_item_id: s,
    contact_id: t,
    send_to: a
  }, r, i, l);
}, b = (n) => async (s, e, t, a) => {
  const { getState: r } = n, { tokenHandlers: { spacePermissionTokenHandler: o, subscriptionTokenHandler: i } } = r();
  return (await c.getOrCreateInstance().emailMarketing().listEmailMetrics(s, e, {
    limit: t,
    offset: a
  }, o, i)).data;
}, C = (n) => async (s, e) => {
  const { getState: t } = n, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: r } } = t();
  return (await c.getOrCreateInstance().emailMarketing().getEmailMetricsSummary(s, e, a, r)).data;
};
export {
  E as createEmailMessage,
  H as getEmailMessage,
  C as getEmailMetricsSummary,
  u as listEmailCampaigns,
  M as listEmailMessages,
  b as listEmailMetrics,
  P as processEmailMessage,
  S as sendPreviewEmail,
  w as updateEmailMessage,
  y as updateEmailReplyTo,
  T as updateEmailSubject
};

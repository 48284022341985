import { makeApiSliceCreator as A } from "@typedream/zustand-query";
import { getAIContext as i, createAIContext as x, updateAIContext as C, listAIContext as I, deleteAIContext as c } from "./actions.js";
const l = A("aiContextApi", (e) => ({
  endpoints: (o) => ({
    getAIContext: o.query(i(e)),
    createAIContext: o.mutation(x(e), {
      invalidateQueries: ({ args: t }) => [["listAIContext", t[0], t[1]]]
    }),
    updateAIContext: o.mutation(C(e), {
      onSuccess: ({ args: t }) => {
        const { aiContextApi: n } = e.getState();
        n.invalidateQueries(["listAIContext", t[0], "*"], ["getAIContext", t[0], t[1]]);
      }
    }),
    listAIContext: o.query(I(e)),
    deleteAIContext: o.mutation(c(e), {
      onSuccess: ({ args: t }) => {
        const { aiContextApi: n } = e.getState();
        n.invalidateQueries(["listAIContext", t[0], "*"], ["getAIContext", t[0], t[1]]);
      }
    })
  })
}));
export {
  l as createAIContextApiSlice
};

import { makeApiSliceCreator as a } from "@typedream/zustand-query";
import { getPublicTemplate as m, getTemplate as p, createTemplate as s, updateTemplate as T, listSectionTemplates as i, listTemplates as o, listTemplatesPublic as u, updateTemplateUsage as n, processTemplateBeforeUsage as c, publishTemplate as q, unpublishTemplate as r, deleteTemplate as v } from "./action.js";
const f = a("templatesApi", (l) => ({
  endpoints: (t) => ({
    getPublicTemplate: t.query(m()),
    getTemplate: t.query(p(l)),
    createTemplate: t.mutation(s(l), {
      invalidateQueries: ({ args: e }) => [["listTemplates", e[0], "*", "*"]]
    }),
    updateTemplate: t.mutation(T(l), {
      invalidateQueries: ({ args: e }) => [
        ["listTemplates", e[0], "*", "*"],
        ["getTemplate", e[0], e[1]]
      ]
    }),
    listSectionTemplates: t.query(i()),
    listTemplates: t.query(o(l)),
    listTemplatesPublic: t.query(u()),
    updateTemplateUsage: t.mutation(n(l)),
    processTemplateBeforeUsage: t.mutation(c(l)),
    publishTemplate: t.mutation(q(l), {
      invalidateQueries: ({ args: e }) => [
        ["listTemplates", e[0], "*", "*"],
        ["getTemplate", e[0], e[1]]
      ]
    }),
    unpublishTemplate: t.mutation(r(l), {
      invalidateQueries: ({ args: e }) => [
        ["listTemplates", e[0], "*", "*"],
        ["getTemplate", e[0], e[1]]
      ]
    }),
    deleteTemplate: t.mutation(v(l), {
      invalidateQueries: ({ args: e }) => [
        ["listTemplates", e[0], "*", "*"],
        ["getTemplate", e[0], e[1]]
      ]
    })
  })
}));
export {
  f as createTemplatesApiSlice
};

import { makeApiSliceCreator as o } from "@typedream/zustand-query";
import { getSubscription as e, getProrateInfo as i } from "./actions.js";
const c = o("subscriptionApi", (r) => ({
  endpoints: (t) => ({
    getSubscription: t.query(e(r)),
    getProrateInfo: t.query(i(r))
  })
}));
export {
  c as createSubscriptionApiSlice
};

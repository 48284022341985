'use client';

import { cva, VariantProps } from 'class-variance-authority';
import React from 'react';

const badgeVariants = cva(
  ['flex', 'justify-center', 'items-center', 'font-semibold', 'rounded-md', 'w-fit leading-none'],
  {
    variants: {
      uppercase: {
        true: 'uppercase',
        false: 'normal-case',
      },
      hideBorder: {
        true: 'border-0 border-none',
        false: 'border border-solid',
      },
      variant: {
        purple: 'border-indigo-200 bg-background-button-accent-soft text-accent',
        yellow: 'border-yellow-200 bg-yellow-50 text-yellow-500',
        red: 'border-red-200 bg-red-25 text-red-500',
        green: 'border-green-200 bg-green-50 text-green-500',
        blue: 'border-blue-200 bg-blue-50 text-blue-500',
        gray: 'border-gray-200 bg-gray-50 text-gray-500',
      },
      size: {
        xxs: 'text-[10px] px-1.5 py-1',
        xs: 'text-xs px-2 py-1',
        sm: 'text-sm px-2 py-1',
        md: 'text-base px-2 py-1',
      },
    },
    defaultVariants: {
      hideBorder: false,
      variant: 'purple',
      size: 'xxs',
      uppercase: true,
    },
  }
);

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

export const Badge = React.forwardRef<HTMLDivElement, BadgeProps>((props, ref) => {
  return (
    <div
      className={badgeVariants({
        variant: props.variant,
        hideBorder: props.hideBorder,
        size: props.size,
        className: props.className,
        uppercase: props.uppercase,
      })}
    >
      <span>{props.children}</span>
    </div>
  );
});

const i = (r, n) => {
  let e = JSON.parse(JSON.stringify(r));
  if (e.children) {
    const t = e.children.map((f) => i(f, n));
    e.children = t;
  }
  return n.forEach((t) => {
    e = t(e);
  }), e;
}, o = (r, n) => r.map((e) => i(e, n));
function c(r, n) {
  !r || (n.forEach((e) => {
    e.process(r);
  }), !("text" in r || !r.children) && r.children.forEach((e) => {
    c(e, n);
  }));
}
function a(r, n) {
  r.forEach((e) => c(e, n));
}
export {
  a as IterateContent,
  o as MutateContent
};

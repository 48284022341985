import { ComponentClass, useEffect } from 'react';
import '../styles/reactGridStyles.css';
import { ThemeProvider } from 'next-themes';
import type { AppProps } from 'next/app';
import 'requestidlecallback-polyfill';
import { useRouter } from 'next/router';
import { NextPageContext } from 'next';
import dynamic from 'next/dynamic';
import '../styles/styles.scss';
import '../styles/globals.css';
import '@typedream/components/dist/style.css';
// import '@typedream/dream-editor/dist/style.css';
import Script from 'next/script';
import mixpanel from 'mixpanel-browser';
import { init as CommandbarInit } from 'commandbar';
import { googleFonts } from '@typedream/core';
import { API } from '@typedream/data';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MicrosoftClarity from '@/head/MicrosoftClarity';
import PublishSettingsUpdatePopup from '@/components/PublishSettingsUpdatePopup';
import { isProduction } from '@/utils/envCheck';
import TagManager from 'react-gtm-module';
import { Toaster } from '@typedream/ui';
import PosthogWrapperProvider from '@/data/posthog';
import TokenProvider from '../utils/jwt/tokenProvider';
import { COMMANDBAR_KEY, EDITOR_API_BASE_URL, MIX_PANEL_KEY, PORTAL_ID } from '../constants';
import Notification from '../components/Notification';
import Rewardful from '../head/Rewardful';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@typedream/ui/ui-globals.css';

const Font = dynamic(() => import('../head/Font'));
const TwitterPixel = dynamic(() => import('../head/TwitterPixel'));

mixpanel.init(MIX_PANEL_KEY);
API.init(EDITOR_API_BASE_URL);

function MyApp({
  Component,
  pageProps,
}: AppProps & {
  Component: ComponentClass & { getInitialProps?(context: NextPageContext): any } & {
    theme: string;
  };
}) {
  const router = useRouter();

  useEffect(() => {
    mixpanel.track_pageview(); // track initial page view
  }, []);

  useEffect(() => {
    CommandbarInit(COMMANDBAR_KEY);
  }, []);

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: 'GTM-WTZ8GQJR',
    };

    TagManager.initialize(tagManagerArgs);
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      // track pageview (mixpanel doesn't automatically track pageview on SPA)
      // https://docs.mixpanel.com/docs/tracking-methods/sdks/javascript#tracking-page-views
      mixpanel.track_pageview();
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Load font maps and return as a font family string for webfontloader
      const fontMap = Object.keys(googleFonts);
      const fontsToLoad = fontMap.map((font) => {
        const currentFont = googleFonts[font];
        return `${currentFont.family}:${currentFont.variants.join(',')}`;
      });

      import('webfontloader').then((WebFont) => {
        WebFont.load({
          google: {
            families: fontsToLoad,
          },
        });
      });
    }
  }, []);

  return (
    <PosthogWrapperProvider>
      <DndProvider backend={HTML5Backend}>
        <Script src="https://js.stripe.com/v3/" />
        <TokenProvider>
          <ThemeProvider forcedTheme={Component.theme || null}>
            <Font />
            <Component {...pageProps} />
            <div id={PORTAL_ID} />
            <Notification />
          </ThemeProvider>
        </TokenProvider>
        <PublishSettingsUpdatePopup />
        {isProduction && (
          <>
            <TwitterPixel />
            {
              // Temporarily disable Microsoft Clarity on the editor page
              router.pathname !== '/s/[slug]/[space_id]/[page_id]' && <MicrosoftClarity />
            }
            <Rewardful />
          </>
        )}
        <Toaster />
      </DndProvider>
    </PosthogWrapperProvider>
  );
}

export default MyApp;

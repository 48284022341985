import { API as c } from "../../api/api.js";
const g = (a) => async (n) => {
  var o;
  if (!n)
    return {};
  const { getState: e } = a, { tokenHandlers: { spacePermissionTokenHandler: s } } = e(), r = await c.getOrCreateInstance().aiProject().listProjects(n, s);
  return Object.fromEntries((o = r == null ? void 0 : r.data) == null ? void 0 : o.map((i) => [i.id, i]));
}, l = () => async () => {
  var e;
  const a = await c.getOrCreateInstance().aiProject().listProjectsByUserID();
  return Object.fromEntries((e = a == null ? void 0 : a.data) == null ? void 0 : e.map((s) => [s.id, s]));
}, j = (a) => async (n) => {
  const { getState: e } = a, { tokenHandlers: { spacePermissionTokenHandler: s, subscriptionTokenHandler: r } } = e(), t = await c.getOrCreateInstance().aiProject().createProject(n, s, r);
  return t == null ? void 0 : t.data;
}, u = (a) => async (n, e) => {
  if (!e || !n)
    return;
  const { getState: s } = a, { tokenHandlers: { spacePermissionTokenHandler: r } } = s(), t = await c.getOrCreateInstance().aiProject().getProject(n, e, r);
  return t == null ? void 0 : t.data;
}, k = (a) => async (n) => {
  const { getState: e } = a, { tokenHandlers: { spacePermissionTokenHandler: s, subscriptionTokenHandler: r } } = e(), t = await c.getOrCreateInstance().aiProject().updateProject(n, s, r);
  return t == null ? void 0 : t.data;
}, H = (a) => async (n, e) => {
  var i;
  if (!e || !n)
    return {};
  const { getState: s } = a, { tokenHandlers: { spacePermissionTokenHandler: r } } = s(), t = await c.getOrCreateInstance().aiProject().listPages(n, e, r);
  return Object.fromEntries((i = t == null ? void 0 : t.data) == null ? void 0 : i.map((P) => [P.id, P]));
}, m = (a) => async (n, e) => {
  const { getState: s } = a, { tokenHandlers: { spacePermissionTokenHandler: r, subscriptionTokenHandler: t } } = s(), o = await c.getOrCreateInstance().aiProject().createPage(n, e, r, t);
  return o == null ? void 0 : o.data;
}, I = (a) => async (n, e) => {
  const { getState: s } = a, { tokenHandlers: { spacePermissionTokenHandler: r, subscriptionTokenHandler: t } } = s(), o = await c.getOrCreateInstance().aiProject().updatePage(n, e, r, t);
  return o == null ? void 0 : o.data;
}, O = (a) => async (n, e) => {
  if (!e || !n)
    return;
  const { getState: s } = a, { tokenHandlers: { spacePermissionTokenHandler: r } } = s(), t = await c.getOrCreateInstance().aiProject().getPage(n, e, r);
  return t == null ? void 0 : t.data;
};
export {
  m as createAIPage,
  j as createAIProject,
  O as getAIPage,
  u as getAIProject,
  H as listAIPages,
  g as listAIProjects,
  l as listAIProjectsByUserID,
  I as updateAIPage,
  k as updateAIProject
};

import { API as i } from "../../api/api.js";
import { makeValidPath as u } from "../../utils/makeValidPath.js";
import { DEFAULT_NEW_PAGE_SLUG_RANDOM_LENGTH as p } from "../../constants/constants.js";
const m = (r) => async ({ productData: t, spaceID: e }) => {
  if (!e)
    return;
  const { getState: n } = r, { tokenHandlers: s } = n(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: a } = s, c = Math.random().toString(36).substring(5, 5 + p), d = {
    ...t,
    product_slug: u(`${t.product_name}-${c}`)
  };
  return (await i.getOrCreateInstance().products().create(d, e, o, a)).data;
}, P = (r) => async (t, e) => {
  if (!t)
    return;
  const { getState: n } = r, { tokenHandlers: s } = n(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: a } = s;
  return (await i.getOrCreateInstance().products().query(t, e, o, a)).data;
}, T = (r) => async (t, e) => {
  if (!e || !t)
    return;
  const { getState: n } = r, { tokenHandlers: s } = n(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: a } = s;
  return (await i.getOrCreateInstance().products().get(t, e, o, a)).data;
}, f = (r) => async ({ productID: t, spaceID: e, productData: n }) => {
  if (!e || !t || !n)
    return;
  const { getState: s } = r, { tokenHandlers: o } = s(), { spacePermissionTokenHandler: a, subscriptionTokenHandler: c } = o;
  return (await i.getOrCreateInstance().products().update(t, e, n, a, c)).data;
}, S = (r) => async ({ productID: t, spaceID: e, published: n }) => {
  if (!e || !t)
    return;
  const { getState: s } = r, { tokenHandlers: o } = s(), { spacePermissionTokenHandler: a, subscriptionTokenHandler: c } = o;
  return (await i.getOrCreateInstance().products().publish(t, e, n, a, c)).data;
}, C = (r) => async (t) => {
  if (!t)
    return;
  const { getState: e } = r, { tokenHandlers: n } = e(), { spacePermissionTokenHandler: s, subscriptionTokenHandler: o } = n;
  return (await i.getOrCreateInstance().products().getProductCollection(t, s, o)).data;
}, b = (r) => async ({ spaceID: t, contactEmail: e }) => {
  if (!t || !e)
    return;
  const { getState: n } = r, { tokenHandlers: s } = n(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: a } = s;
  return (await i.getOrCreateInstance().products().updateContactEmail(t, e, o, a)).data;
}, v = (r) => async ({ spaceID: t, senderName: e }) => {
  if (!t || !e)
    return;
  const { getState: n } = r, { tokenHandlers: s } = n(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: a } = s;
  return (await i.getOrCreateInstance().products().updateSenderName(t, e, o, a)).data;
}, y = (r) => async (t) => {
  if (!t)
    return;
  const { getState: e } = r, { tokenHandlers: n } = e(), { spacePermissionTokenHandler: s, subscriptionTokenHandler: o } = n;
  return (await i.getOrCreateInstance().products().countProducts(t, s, o)).data;
}, O = (r) => async ({ productID: t, spaceID: e }) => {
  if (!t || !e)
    return;
  const { getState: n } = r, { tokenHandlers: s } = n(), { spacePermissionTokenHandler: o, subscriptionTokenHandler: a } = s;
  return (await i.getOrCreateInstance().products().delete(t, e, o, a)).data;
};
export {
  y as countProducts,
  m as createProduct,
  O as deleteProduct,
  T as getProduct,
  C as getProductCollection,
  S as publishProduct,
  P as queryProducts,
  b as updateContactEmail,
  f as updateProduct,
  v as updateSenderName
};

import { API as i } from "../../api/api.js";
const b = (n) => async (e) => {
  const { getState: o } = n, { tokenHandlers: { subscriptionTokenHandler: r } } = o(), t = await r.refreshSubscriptionToken(e);
  return t == null ? void 0 : t.subscription;
}, g = (n) => async (e, { plan: o, cycle: r, promoCode: t }) => {
  const { getState: s } = n, { tokenHandlers: { spacePermissionTokenHandler: a, subscriptionTokenHandler: c } } = s();
  return await i.getOrCreateInstance().subscription().getProrateInfo(e, o, r, a, c, t);
};
export {
  g as getProrateInfo,
  b as getSubscription
};

import { makeApiSliceCreator as i } from "@typedream/zustand-query";
import { listCoupons as e } from "./actions.js";
const n = i("couponApi", (o) => ({
  endpoints: (p) => ({
    listCoupons: p.query(e(o))
  })
}));
export {
  n as createCouponApiSlice
};

import { API as o } from "../../api/api.js";
const p = (t) => async (e) => {
  const { getState: n } = t, { tokenHandlers: { spacePermissionTokenHandler: s } } = n();
  return await o.getOrCreateInstance().contact().count({}, e, s);
}, l = (t) => async (e, n, s) => {
  const { getState: a } = t, { tokenHandlers: { spacePermissionTokenHandler: r } } = a();
  return await o.getOrCreateInstance().contact().list({ limit: n, offset: s }, e, r);
}, d = (t) => async (e, n) => {
  const { getState: s } = t, { tokenHandlers: { spacePermissionTokenHandler: a } } = s();
  return await o.getOrCreateInstance().contact().upload(n, e, a);
}, m = (t) => async (e, n) => {
  const { getState: s } = t, { tokenHandlers: { spacePermissionTokenHandler: a } } = s();
  return await o.getOrCreateInstance().contact().filter(e, n, a);
}, u = (t) => async (e, n, s) => {
  const { getState: a } = t, { tokenHandlers: { spacePermissionTokenHandler: r } } = a();
  return await o.getOrCreateInstance().contact().previewProductEmailImport(e, n, s, r);
}, g = (t) => async (e, n, s) => {
  const { getState: a } = t, { tokenHandlers: { spacePermissionTokenHandler: r } } = a();
  return await o.getOrCreateInstance().contact().importProductEmail(e, n, s, r);
};
export {
  p as countContacts,
  g as importProductEmailContacts,
  l as listContacts,
  m as previewFilterContacts,
  u as previewProductEmailImportContacts,
  d as uploadContacts
};

var d = Object.defineProperty;
var n = (r, o, e) => o in r ? d(r, o, { enumerable: !0, configurable: !0, writable: !0, value: e }) : r[o] = e;
var a = (r, o, e) => (n(r, typeof o != "symbol" ? o + "" : o, e), e);
import { JwtToken as l } from "./JwtToken.js";
import { SPACE_ROLE as t } from "../../types.js";
import { SPACE_PERMISSIONS as i } from "../../constants/constants.js";
class y extends l {
  constructor(e) {
    super(e);
    a(this, "payload");
    this.payload = this.decodePayload();
  }
  decodePayload() {
    return super.decodePayload();
  }
  getPermissionID() {
    return this.payload.permission_id;
  }
  getUserID() {
    return this.payload.user_id;
  }
  getSpaceID() {
    return this.payload.space_id;
  }
  getRole() {
    return this.payload.role;
  }
  isAllowed(e) {
    const s = e in i ? i[e] : {
      [t.OWNER]: !0,
      [t.ADMIN]: !0,
      [t.WRITER]: !0,
      [t.READER]: !1
    };
    return s ? !!s[this.getRole()] : !1;
  }
}
export {
  y as SpacePermissionToken
};

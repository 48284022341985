'use client';

import { CheckCircle, XCircle } from 'phosphor-react';
import {
  Toast,
  ToastClose,
  ToastDescription,
  ToastProvider,
  ToastTitle,
  ToastViewport,
} from './Toast';
import { useToast } from './useToast';

export function Toaster() {
  const { toasts } = useToast();

  return (
    <ToastProvider>
      {toasts.map(function ({ id, title, description, variant, action, ...props }) {
        return (
          <Toast key={id} {...props}>
            <div className="flex items-center gap-2.5 w-full">
              {variant === 'destructive' && <XCircle size={20} className="text-danger" />}
              {variant === 'success' && <CheckCircle size={20} className="text-success" />}
              <div className="flex flex-col flex-1">
                {title && <ToastTitle>{title}</ToastTitle>}
                {description && <ToastDescription>{description}</ToastDescription>}
              </div>
              {action}
            </div>
            <ToastClose />
          </Toast>
        );
      })}
      <ToastViewport />
    </ToastProvider>
  );
}

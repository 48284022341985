import { makeApiSliceCreator as c } from "@typedream/zustand-query";
import { queryProducts as u, getProduct as r, getProductCollection as d, countProducts as a, createProduct as n, updateProduct as P, publishProduct as s, updateContactEmail as i, updateSenderName as p, deleteProduct as l } from "./actions.js";
const I = c("productsApi", (e) => ({
  endpoints: (o) => ({
    queryProducts: o.query(u(e)),
    getProduct: o.query(r(e)),
    getProductCollection: o.query(d(e)),
    countProducts: o.query(a(e)),
    createProduct: o.mutation(n(e), {
      invalidateQueries: ({ args: t }) => [
        ["queryProducts", t[0].spaceID, "*"],
        ["countProducts", t[0].spaceID],
        ["getProductCollection", t[0].spaceID]
      ]
    }),
    updateProduct: o.mutation(P(e), {
      invalidateQueries: ({ args: t }) => [
        ["queryProducts", t[0].spaceID, "*"],
        ["getProduct", t[0].productID, t[0].spaceID]
      ]
    }),
    publishProduct: o.mutation(s(e), {
      invalidateQueries: ({ args: t }) => [
        ["queryProducts", t[0].spaceID, "*"],
        ["getProduct", t[0].productID, t[0].spaceID]
      ]
    }),
    updateContactEmail: o.mutation(i(e), {
      invalidateQueries: ({ args: t }) => [["getProductCollection", t[0].spaceID]]
    }),
    updateSenderName: o.mutation(p(e), {
      invalidateQueries: ({ args: t }) => [["getProductCollection", t[0].spaceID]]
    }),
    deleteProduct: o.mutation(l(e), {
      invalidateQueries: ({ args: t }) => [
        ["queryProducts", t[0].spaceID, "*"],
        ["countProducts", t[0].spaceID],
        ["getProductCollection", t[0].spaceID]
      ]
    })
  })
}));
export {
  I as createProductsApiSlice
};

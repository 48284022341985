import { makeApiSliceCreator as S } from "@typedream/zustand-query";
import { saveSpaceInLocalStorage as o } from "../../utils/saveSpaceInLocalStorage.js";
import { listSpaces as u, getSpace as m, getOrCreatePersonalSpace as r, getSpacePermissions as d, createSpace as g, createSpaceNoPages as l, deleteSpace as v, saveNavbar as D, updateRoutes as I, publish as P, updateSpaceCustomizationPartial as Q, updateSpaceSettings as C, updateSpaceMetadata as f, addSpacePermission as A, removeSpacePermission as R, transferSpacePermission as _, updateCustomDomain as y, updateCustomDomainRedirect as O, deleteCustomDomain as q, deleteCustomDomainRedirect as E, createUnclaimedSpace as N, claimSpace as h } from "./actions.js";
import { DEFAULT_SPACE_STORAGE_KEY as n } from "../../constants/constants.js";
import { sanitizeSpace as j } from "../../utils/sanitizeSpace.js";
const k = S("spacesApi", (t) => ({
  endpoints: (a) => ({
    listSpaces: a.query(u(), {
      onSuccess: ({ data: e }) => {
        e && o(Object.values(e));
      }
    }),
    getSpace: a.query(m(t), {
      onSuccess: ({ args: e, data: s }) => {
        var p;
        const { spacesApi: c } = t.getState();
        if (!s)
          return;
        const i = {
          ...(p = c.endpoints.listSpaces.get()) == null ? void 0 : p.data,
          [e[0]]: s
        };
        o(Object.values(i));
      }
    }),
    getOrCreatePersonalSpace: a.query(r(), {
      onSuccess: ({ data: e }) => {
        var i;
        const { spacesApi: s } = t.getState(), c = {
          ...(i = s.endpoints.listSpaces.get()) == null ? void 0 : i.data,
          [e.id]: e
        };
        o(Object.values(c));
      }
    }),
    getSpacePermissions: a.query(d(t)),
    createSpace: a.mutation(g(), {
      invalidateQueries: () => [["listSpaces"]]
    }),
    createSpaceNoPages: a.mutation(l(), {
      invalidateQueries: () => [["listSpaces"]]
    }),
    deleteSpace: a.mutation(v(t), {
      onSuccess: ({ args: e }) => {
        (localStorage == null ? void 0 : localStorage.getItem(n)) === e[0] && (localStorage == null || localStorage.removeItem(n));
      },
      invalidateQueries: ({ args: e }) => [["listSpaces"], ["getSpace", e[0]]]
    }),
    saveNavbar: a.mutation(D(t), {
      onSuccess: ({ args: e, data: s }) => {
        const { spacesApi: c } = t.getState();
        s && c.endpoints.getSpace.get(e[0].spaceID).updateData((i) => ({
          ...i,
          navbar: s.navbar,
          updated_at: s.updated_at
        }));
      }
    }),
    updateRoutes: a.mutation(I(t), {
      onStart: async ({ args: e, promise: s }) => {
        const { spacesApi: c } = t.getState(), i = c.endpoints.getSpace.get(e[0].spaceID).updateData((p) => j({
          ...p,
          routes: e[0].routes
        }));
        try {
          await s;
        } catch {
          i.undo();
        }
      },
      invalidateQueries: ({ args: e }) => [["listSpaces"], ["getSpace", e[0].spaceID]]
    }),
    publish: a.mutation(P(t), {
      onSuccess: ({ args: e }) => {
        const { pagesApi: s, spacesApi: c } = t.getState();
        s.invalidateQueries(["listPages", e[0].spaceID]), c.invalidateQueries(["getSpace", e[0].spaceID]);
      }
    }),
    updateSpaceCustomizationPartial: a.mutation(Q(t), {
      onSuccess: ({ args: e, data: s }) => {
        const { spacesApi: c } = t.getState();
        c.endpoints.getSpace.get(e[0].spaceID).updateData(() => ({
          ...s,
          updated_at: s.updated_at
        }));
      },
      invalidateQueries: ({ args: e }) => [["getSpace", e[0].spaceID]]
    }),
    updateSpaceSettings: a.mutation(C(t), {
      invalidateQueries: ({ args: e }) => [["getSpace", e[0].spaceID]]
    }),
    updateSpaceMetadata: a.mutation(f(t), {
      invalidateQueries: ({ args: e }) => [["getSpace", e[0].spaceID]]
    }),
    addSpacePermission: a.mutation(A(t), {
      invalidateQueries: ({ args: e }) => [["getSpacePermissions", e[0].spaceID]]
    }),
    removeSpacePermission: a.mutation(R(t), {
      invalidateQueries: ({ args: e }) => [["getSpacePermissions", e[0].spaceID]]
    }),
    transferSpacePermission: a.mutation(_(t), {
      invalidateQueries: ({ args: e }) => [["listSpaces"], ["getSpacePermissions", e[0].spaceID]]
    }),
    updateCustomDomain: a.mutation(y(t), {
      invalidateQueries: ({ args: e }) => [["listSpaces"], ["getSpace", e[0].spaceID]]
    }),
    updateCustomDomainRedirect: a.mutation(O(t), {
      invalidateQueries: ({ args: e }) => [["listSpaces"], ["getSpace", e[0].spaceID]]
    }),
    deleteCustomDomain: a.mutation(q(t), {
      invalidateQueries: ({ args: e }) => [["listSpaces"], ["getSpace", e[0]]]
    }),
    deleteCustomDomainRedirect: a.mutation(E(t), {
      invalidateQueries: ({ args: e }) => [["listSpaces"], ["getSpace", e[0]]]
    }),
    createUnclaimedSpace: a.mutation(N()),
    claimSpace: a.mutation(h())
  })
}));
export {
  k as createSpacesApiSlice
};

import type { CSSProperties } from 'react';
import type { Descendant } from 'slate';
import { AddPropertiesDeep } from '../utils';
import { Navbar } from './navbar';

type InlineElement = TextLeaf | InlineLinkElement;

export type ParagraphElement = {
  id: string;
  type: 'p';
  width?: number;
  width_max?: '100%';
  align?: AlignType;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  animation?: BlockAnimation;
  children: InlineElement[];
  padding?: CSSProperties['padding'];
  margin?: CSSProperties['margin'];
  customAttributes?: CustomAttribute;
};

export type Heading1Element = {
  id: string;
  type: 'h1';
  width?: number;
  width_max?: '100%';
  align?: AlignType;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  animation?: BlockAnimation;
  children: InlineElement[];
  padding?: CSSProperties['padding'];
  margin?: CSSProperties['margin'];
  customAttributes?: CustomAttribute;
};

export type Heading2Element = {
  id: string;
  type: 'h2';
  width?: number;
  width_max?: '100%';
  align?: AlignType;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  animation?: BlockAnimation;
  children: InlineElement[];
  padding?: CSSProperties['padding'];
  margin?: CSSProperties['margin'];
  customAttributes?: CustomAttribute;
};

export type Heading3Element = {
  id: string;
  type: 'h3';
  width?: number;
  width_max?: '100%';
  align?: AlignType;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  animation?: BlockAnimation;
  children: InlineElement[];
  padding?: CSSProperties['padding'];
  margin?: CSSProperties['margin'];
  customAttributes?: CustomAttribute;
};

export type NumberedListElement = {
  id: string;
  type: 'ol';
  width?: number;
  width_max?: '100%';
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: ListItemElement[];
  customAttributes?: CustomAttribute;
};

export type BulletedListElement = {
  id: string;
  type: 'ul';
  width?: number;
  width_max?: '100%';
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: ListItemElement[];
  customAttributes?: CustomAttribute;
};

export type ListItemElement = {
  id: string;
  type: 'li';
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: Array<
    | ParagraphElement
    | Heading1Element
    | Heading2Element
    | Heading3Element
    | ImageElement
    | ButtonElement
    | ContainerElement
    | MediaEmbedElement
    | NumberedListElement
    | BulletedListElement
    | TodoListElement
  >;
  customAttributes?: CustomAttribute;
};

export type TodoListElement = {
  id: string;
  type: 'action_item';
  width?: number;
  width_max?: '100%';
  checked?: boolean;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: InlineElement[];
  customAttributes?: CustomAttribute;
};

export type CodeBlockElement = {
  id: string;
  type: 'code_block';
  width?: number;
  width_max?: '100%';
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: CodeLineElement[];
  customAttributes?: CustomAttribute;
  margin?: CSSProperties['margin'];
  border_radius?: CSSProperties['borderRadius'];
  box_shadow?: CSSProperties['boxShadow'];
};

export type CodeLineElement = {
  id: string;
  type: 'code_line';
  children: [{ id: string; text: string }];
};

export type BlockquoteElement = {
  id: string;
  type: 'blockquote';
  align?: AlignType;
  width?: number;
  width_max?: '100%';
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: InlineElement[];
  customAttributes?: CustomAttribute;
};

export type InlineLinkElement = {
  id: string;
  type: 'a';
  url: string;
  internal_link?: boolean;
  page_id?: string;
  open_in_new_tab?: boolean;
  children: InlineElement[];
  customAttributes?: CustomAttribute;
};

export type ImageElement = {
  id: string;
  type: 'img';
  url: string;
  height?: number;
  width?: number;
  aspect_ratio?: number;
  align?: AlignType;
  alt_text?: string;
  href?: string;
  internal_link?: boolean;
  page_id?: string;
  open_in_new_tab?: boolean;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  animation?: BlockAnimation;
  border_radius?: CSSProperties['borderRadius'];
  crop?: CropParameters;
  children: EmptyChildren;
  customAttributes?: CustomAttribute;
  objectFit?: string;
  margin?: CSSProperties['margin'];
  box_shadow?: CSSProperties['boxShadow'];
};

export type ColumnElement = {
  id: string;
  type: 'column';
  height?: number;
  width?: number;
  width_max?: '100%';
  align?: AlignType;
  vertical_align?: VerticalAlignType;
  mobile_direction?: 'direction_horizontal' | 'direction_vertical';
  mobile_reverse_columns?: boolean;
  mobile_horizontal_overflow?: 'squeeze' | 'scroll';
  background?: string;
  backgroundImage?: string;
  backgroundDarken?: boolean;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: ColumnItemElement[];
  customAttributes?: CustomAttribute;
  padding?: CSSProperties['padding'];
  margin?: CSSProperties['margin'];
  gap?: CSSProperties['gap'];
  border_radius?: CSSProperties['borderRadius'];
  box_shadow?: CSSProperties['boxShadow'];
};

export type ColumnItemElement = {
  id: string;
  type: 'column_item';
  width: number;
  children: Descendant[];
  customAttributes?: CustomAttribute;
};

type ButtonIconProps =
  | {
      content: {
        type: string;
        name: string;
        color: CSSProperties['color'];
      };
      type: 'icon';
    }
  | {
      content: string;
      type: 'emoji' | 'image';
    };
export type ButtonElement = {
  id: string;
  type: 'button';
  buttonType?: 'button' | 'submit' | 'reset';
  color?: string;
  background?: string;
  align?: AlignType;
  icon?: ButtonIconProps;
  width?: number;
  width_max?: '100%';
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  url?: string;
  internal_link?: boolean;
  page_id?: string;
  open_in_new_tab?: boolean;
  animation?: BlockAnimation;
  children: InlineElement[];
  customAttributes?: CustomAttribute;
  variant?: 'primary' | 'secondary';
  style?: 'plain' | 'glowing_button';
  glowing_background?: 'black' | 'white';
  margin?: CSSProperties['margin'];
  border_radius?: CSSProperties['borderRadius'];
  box_shadow?: CSSProperties['boxShadow'];
};

export type MediaEmbedElement = {
  id: string;
  type: 'media_embed';
  height?: number;
  width?: number;
  width_max?: '100%';
  keep_aspect_ratio?: boolean;
  url?: string;
  html?: string;
  file?: string;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: EmptyChildren;
  customAttributes?: CustomAttribute;
  margin?: CSSProperties['margin'];
};

export type DividerElement = {
  id: string;
  type: 'divider';
  width?: number;
  width_max?: '100%';
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: EmptyChildren;
  customAttributes?: CustomAttribute;
  margin?: CSSProperties['margin'];
};

export type ContainerElement = {
  id: string;
  type: 'container';
  height?: number;
  width?: number;
  width_max?: '100%';
  align?: AlignType;
  vertical_align?: VerticalAlignType;
  style?: 'plain' | 'card';
  background?: string;
  backgroundImage?: string;
  backgroundDarken?: boolean;
  backgroundVideo?: string;
  keep_aspect_ratio?: boolean;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  url?: string;
  internal_link?: boolean;
  page_id?: string;
  open_in_new_tab?: boolean;
  animation?: BlockAnimation;
  children: Descendant[];
  customAttributes?: CustomAttribute;
  // for synced container
  transclusion_enabled?: boolean;
  transclusion_reference_id?: string;
  transclusion_updated_at?: number;
  name?: string;
  padding?: CSSProperties['padding'];
  margin?: CSSProperties['margin'];
  overflow?: CSSProperties['overflow'];
  border_radius?: CSSProperties['borderRadius'];
  box_shadow?: CSSProperties['boxShadow'];
};

// basically same as ContainerElement with some optional fields made required
export type SyncedContainerElement = ContainerElement & {
  transclusion_enabled: boolean;
  transclusion_reference_id: string;
  transclusion_updated_at: number;
  name: string;
};

export type SpacerElement = {
  id: string;
  type: 'spacer';
  height?: number;
  width?: number;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: EmptyChildren;
  customAttributes?: CustomAttribute;
  margin?: CSSProperties['margin'];
};

export type CollectionElement = {
  id: string;
  type: 'collection';
  collection_data_id: string;
  collection_template: {
    databaseTemplateURL:
      | {
          notion: string;
        }
      | undefined;
    templateKey: string;
    template: AddPropertiesDeep<
      Descendant,
      {
        collection_connect_data?: boolean;
        collection_connect_data_keys?: Array<{
          schemaKey: string;
          blockElementKey: 'text' | 'url' | 'backgroundImage' | 'href' | 'html' | 'file';
        }>;
      }
    >;
    schema: {
      [key: string]: string;
    };
    schemaOrder: string[];
    settings: {
      numCol: number;
    };
    previewImage:
      | {
          image: string;
          imageDark: string;
        }
      | undefined;
    custom: boolean;
  };
  width?: number;
  width_max?: '100%';
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: CollectionItemElement[] | EmptyChildren;
  customAttributes?: CustomAttribute;
};

export type CollectionItemElement = {
  id: string;
  type: 'collection_item';
  children: [Descendant];
  customAttributes?: CustomAttribute;
};

export type CollectionCMSElement = {
  id: string;
  type: 'collection_cms';
  collection_data_id: string;
  collection_template: {
    templateKey: string;
    template: AddPropertiesDeep<
      Descendant,
      {
        collection_connect_data?: boolean;
        collection_connect_data_keys?: Array<{
          schemaKey: string;
          blockElementKey: 'text' | 'url' | 'backgroundImage' | 'href' | 'html' | 'file';
        }>;
      }
    >;
    schema: {
      [key: string]: string;
    };
    schemaOrder: string[];
    settings: {
      numCol: number;
    };
  };
  width?: number;
  width_max?: '100%';
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: CollectionItemElement[] | EmptyChildren;
  customAttributes?: CustomAttribute;
};

export type RichTextContentElement = {
  id: string;
  type: 'rich_text_content';
  width?: number;
  width_max?: '100%';
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: Descendant[];
  customAttributes?: CustomAttribute;
};

export type AccordionElement = {
  id: string;
  type: 'accordion';
  style?: 'plain' | 'card' | 'button';
  width?: number;
  width_max?: '100%';
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: AccordionItemElement[];
  customAttributes?: CustomAttribute;
  margin?: CSSProperties['margin'];
};

export type AccordionItemElement = {
  id: string;
  type: 'accordion_item';
  background?: string;
  backgroundImage?: string;
  backgroundDarken?: boolean;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: [AccordionItemHeaderElement, AccordionItemContentElement];
  customAttributes?: CustomAttribute;
};

export type AccordionItemHeaderElement = {
  id: string;
  type: 'accordion_item_header';
  children: [ParagraphElement | Heading1Element | Heading2Element | Heading3Element];
  customAttributes?: CustomAttribute;
};

export type AccordionItemContentElement = {
  id: string;
  type: 'accordion_item_content';
  children: Descendant[];
  customAttributes?: CustomAttribute;
};

export const FormInputTypesWithOptions = ['selection', 'multiselection'] as const;
export const FormInputTypes = ['text', 'number', 'email', ...FormInputTypesWithOptions] as const;
export type FormInputType = (typeof FormInputTypes)[number];

export type FormElement = {
  id: string;
  form_id: string;
  type: 'form';
  height?: number;
  width?: number;
  width_max?: '100%';
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  style?: 'plain' | 'card';
  background?: string;
  backgroundImage?: string;
  backgroundDarken?: boolean;
  redirect_url?: string;
  align?: AlignType;
  vertical_align?: VerticalAlignType;
  children: Descendant[];
  customAttributes?: CustomAttribute;
};

export type FormInputOption = {
  label: string;
  value: number;
};

type BaseInputElement = {
  id: string;
  type: 'form_input';
  inputType: FormInputType;
  name: string;
  label: string;
  is_label_hidden: boolean;
  placeholder: string;
  required: boolean;
  disabled: boolean;
  children: EmptyChildren;
  customAttributes?: CustomAttribute;
};

export type FormInputElementWithOptions = Omit<BaseInputElement, 'inputType'> & {
  inputType: FormInputType;
  options: Array<FormInputOption>;
};

export type FormInputElement = FormInputElementWithOptions | BaseInputElement;

// =============================================
// Mobile
// =============================================

export type GridChildren =
  | CardBackgroundElement
  | CardGridContainerElement
  | CardProductElement
  | NewsletterCardElement;

export type GridElement = {
  id: string;
  type: 'grid';
  cols: number;
  children: GridChildren[];
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  customAttributes?: CustomAttribute;
};

export type GridChildProps = {
  x: number; // column start (0 indexed)
  y: number; // column start (0 indexed)
  w: number; // colspan
  h: number; // rowspan
};

export type GridChild = {
  gridProps: GridChildProps;
};

export type CardBackgroundElement = GridChild & {
  id: string;
  type: 'card_background';
  title: string;
  hideTitle?: boolean;
  subtitle?: string;
  hideSubtitle?: boolean;
  buttonText: string;
  hideButton?: boolean;
  url: string;
  background?: string;
  backgroundImage?: string;
  backgroundVideo?: string;
  children: EmptyChildren;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  customAttributes?: CustomAttribute;
};

export type CardProductItemElement = {
  id: string;
  type: 'card_product_item';
  title: string;
  subtitle?: string;
  arrowColor: string;
  imageUrl: string;
  url: string;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: EmptyChildren;
  customAttributes?: CustomAttribute;
};

export type CardProductElement = GridChild & {
  id: string;
  type: 'card_product';
  title: string;
  subtitle?: string;
  background?: string;
  backgroundImage?: string;
  backgroundVideo?: string;
  url?: string;
  buttonText?: string;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  children: CardProductItemElement[];
  customAttributes?: CustomAttribute;
};

export type CardSocialLinkElement = {
  id: string;
  type: 'card_social_link';
  url: string;
  background?: string;
  hide_followers?: boolean;
  gridProps: {
    sm: GridChildProps;
    md: GridChildProps;
    lg: GridChildProps;
  };
  data: {
    bio?: string;
    followers?: number;
    subscribers?: number;
    following?: number;
    username: string;
    title?: string;
    icon?: string; // icon image
  };
  children: EmptyChildren;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  customAttributes?: CustomAttribute;
};

export type CardGridContainerElement = GridChild & {
  id: string;
  type: 'card_grid_container';
  title?: string;
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  // TODO(@resir014): replace with actual children (SocialCard + LinkCard)
  children: CardSocialLinkElement[];
  customAttributes?: CustomAttribute;
};

export type ProfileElement = {
  id: string;
  type: 'profile';
  title: string;
  subtitle: string;
  image: string;
  children: [SocialLinksElement];
  customAttributes?: CustomAttribute;
};

export type SocialLinkItemElement = {
  id: string;
  type: 'social_link_item';
  url: string;
  icon: string; // icon image
  children: EmptyChildren;
  customAttributes?: CustomAttribute;
};

export type SocialLinksElement = {
  id: string;
  type: 'social_links';
  children: SocialLinkItemElement[];
  customAttributes?: CustomAttribute;
};

export type NewsletterCardElement = GridChild & {
  id: string;
  type: 'newsletter_card';
  title: string;
  subtitle: string;
  children: [FormElement];
  mobile_hide?: boolean;
  desktop_hide?: boolean;
  customAttributes?: CustomAttribute;
};

// =============================================
// Navbar
// =============================================
// TODO(@ibedwi): move to `elements/navbarElement`
export type Navbar2Element = Navbar & {
  id?: string;
  type: 'navbar';
  children: Descendant[]; // TODO(@ibedwi): Update children
};

export type RootElement = {
  id: string;
  type: 'root';
  align?: AlignType;
  vertical_align?: VerticalAlignType;
  vertical_snap_scroll?: boolean;
  background?: string;
  backgroundImage?: string;
  backgroundDarken?: boolean;
  backgroundVideo?: string;
  children: Descendant[];
  customAttributes?: CustomAttribute;
};

export type ContentElement =
  | ParagraphElement
  | Heading1Element
  | Heading2Element
  | Heading3Element
  | NumberedListElement
  | BulletedListElement
  | ListItemElement
  | TodoListElement
  | CodeBlockElement
  | CodeLineElement
  | BlockquoteElement
  | InlineLinkElement
  | ImageElement
  | ColumnElement
  | ColumnItemElement
  | ButtonElement
  | MediaEmbedElement
  | DividerElement
  | ContainerElement
  | SpacerElement
  | CollectionElement
  | CollectionItemElement
  | CollectionCMSElement
  | RichTextContentElement
  | AccordionElement
  | AccordionItemElement
  | AccordionItemHeaderElement
  | AccordionItemContentElement
  | FormElement
  | FormInputElement
  | RootElement
  // TODO(@ibedwi): Update name
  | Navbar2Element
  | GridElement
  | CardProductItemElement
  | CardProductElement
  | CardBackgroundElement
  | ProfileElement
  | CardSocialLinkElement
  | SocialLinkItemElement
  | SocialLinksElement
  | CardGridContainerElement
  | CardProductElement
  | CardProductItemElement
  | NewsletterCardElement;

export type TextLeaf = {
  id?: string;
  text: string;
  bold?: boolean;
  italic?: boolean;
  strikethrough?: boolean;
  code?: boolean;
  underline?: boolean;
  styled?: boolean;
  fontStyle?: string;
  fontFamily?: string;
  fontSize?: number;
  fontWeight?: number;
  letterSpacing?: number;
  lineHeight?: string | number;
  color?: string;
  background?: string;
  gradient?: boolean;
  animation?: LeafAnimation;
};

export type EmptyChildren = [{ id: string; text: '' }];
export type CropOption = {
  name: string;
  aspect: number | undefined;
  circular: boolean;
};
export type CropParameters = {
  originalDimensions: { width: number; height: number };
  cropParameters: {
    x: number;
    y: number;
    width: number;
    height: number;
    unit: '%';
  };
  cropOption: CropOption;
  scale: number;
};

export type AlignType = 'align_left' | 'align_center' | 'align_right' | 'column_dynamic';

export type VerticalAlignType = 'align_start' | 'align_center' | 'align_end';

export type BlockAnimationType = 'fade_in' | 'slide_in' | 'scale_in';
// Animation
export type BlockAnimationFadeIn = {
  type: 'fade_in';
};
export type BlockAnimationSlideIn = {
  type: 'slide_in';
  direction: 'left' | 'right' | 'up' | 'down';
};
export type BlockAnimationScaleIn = {
  type: 'scale_in';
  scaleTo: 'grow' | 'shrink';
};
export type BlockAnimation = BlockAnimationFadeIn | BlockAnimationSlideIn | BlockAnimationScaleIn;

export type LeafAnimationType = 'scroll_words';

export type LeafAnimationScrollWords = {
  type: 'scroll_words';
  words: string[];
};
export type LeafAnimation = LeafAnimationScrollWords;

// editor
export interface IConnectionDataKey {
  schemaKey: string;
  blockElementKey: 'text' | 'url' | 'backgroundImage' | 'href' | 'html' | 'file';
}

export interface UnknownObject {
  [key: string]: unknown;
}

export interface TElement extends UnknownObject {
  children: TDescendant[];
  type: string;
}
export interface TText extends UnknownObject {
  text: string;
}

export declare type TDescendant = TElement | TText;

export type ColumnElementGeneratedAs = 'social_icons' | 'list_of_cards' | 'list_of_buttons';
export type ButtonElementGeneratedAs =
  | 'social_icon_instagram'
  | 'social_icon_twitter'
  | 'social_icon_facebook'
  | 'social_icon_youtube'
  | 'social_icon_linkedin'
  | 'social_icon_tiktok'
  | 'social_icon_email'
  | 'social_icon_website'
  | 'list_item_button';
export type ContainerElementGeneratedAs = 'list_item_background';
export type ImageElementGeneratedAs = 'list_item_image';
export type TextElementGeneratedAs = 'list_item_title' | 'list_item_subtitle';
export type ColumnListTemplate = {
  template: GeneralizeChildrenAndAddEditorProps<ColumnItemElement>;
  preview: string;
};

type GeneralizeChildrenAndAddEditorProps<T> = T extends { children: any }
  ? (Omit<T, 'children'> & {
      children: TDescendant[];
      openOptions?: boolean;
      optionIndex?: number;
      collection_connect_data?: boolean;
      collection_connect_data_keys?: IConnectionDataKey[];
      collection_link_slug?: string;
      collection_link_data_id?: string;
      collection_internal_link?: boolean;
      collection_item_id?: string;
      collection_item_page_id?: string;
      collection_default_item_page_template_key?: string;
    }) &
      (T extends { type: ColumnElement['type'] }
        ? { generated_as?: ColumnElementGeneratedAs; list_template?: ColumnListTemplate }
        : T extends { type: ButtonElement['type'] }
        ? { generated_as?: ButtonElementGeneratedAs }
        : T extends { type: ContainerElement['type'] }
        ? { generated_as?: ContainerElementGeneratedAs }
        : T extends { type: ImageElement['type'] }
        ? { generated_as?: ImageElementGeneratedAs }
        : T extends { type: ParagraphElement['type'] }
        ? { generated_as?: TextElementGeneratedAs }
        : T extends { type: Heading1Element['type'] }
        ? { generated_as?: TextElementGeneratedAs }
        : T extends { type: Heading2Element['type'] }
        ? { generated_as?: TextElementGeneratedAs }
        : T extends { type: Heading3Element['type'] }
        ? { generated_as?: TextElementGeneratedAs }
        : {})
  : never;

export type DreamBlock = GeneralizeChildrenAndAddEditorProps<ContentElement>;
export type DreamValue = DreamBlock[];

type CustomAttribute = {
  id?: string;
  className?: string;
  [key: string]: unknown;
};

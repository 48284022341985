var m = Object.defineProperty;
var S = (n, e, t) => e in n ? m(n, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : n[e] = t;
var o = (n, e, t) => (S(n, typeof e != "symbol" ? e + "" : e, t), t);
import { API as k } from "../../api/api.js";
import { SpacePermissionToken as l } from "./SpacePermissionToken.js";
import { SPACE_PERMISSIONS_STORAGE_KEY as a } from "../../constants/constants.js";
const i = {
  initial: 1,
  fetching: 2,
  errorRetry: 3,
  errorFatal: 4,
  ready: 5
};
class c {
  constructor() {
    o(this, "tokenFetchingState", i.initial);
    o(this, "fetchTokenResp");
  }
  storeTokens(e) {
    var r;
    const s = {
      ...c.getAllSpacePermissions(),
      [e.space_id]: e.token
    };
    try {
      (r = window == null ? void 0 : window.localStorage) == null || r.setItem(a, JSON.stringify(s));
    } catch {
    }
    this.tokenFetchingState = i.initial;
  }
  static getAllSpacePermissions() {
    var t;
    const e = (t = window == null ? void 0 : window.localStorage) == null ? void 0 : t.getItem(a);
    return e ? JSON.parse(e) : null;
  }
  async getSpacePermission(e) {
    const t = c.getAllSpacePermissions();
    let s = t ? t[e] : null, r = null;
    if (s && s.length > 0 && (r = new l(s)), s === void 0 || r === null || r === void 0 || r.getExpiration() < new Date().getTime() / 1e3)
      try {
        s = (await this.refreshSpacePermissionToken(e)).token;
      } catch {
        return null;
      }
    return s ? new l(s) : null;
  }
  async refreshSpacePermissionToken(e) {
    return new Promise((t, s) => {
      const r = () => {
        switch (this.tokenFetchingState) {
          case i.initial:
            this.refreshSpacePermissionTokenAPI(e);
            break;
          case i.ready:
            if (this.fetchTokenResp) {
              t(this.fetchTokenResp), this.tokenFetchingState = i.initial;
              return;
            }
            break;
          case i.errorRetry:
            this.tokenFetchingState = i.initial, s();
            return;
          case i.errorFatal:
            s();
            return;
        }
        setTimeout(r, 0);
      };
      r();
    });
  }
  async refreshSpacePermissionTokenAPI(e) {
    var t;
    if (!e)
      throw Error("Missing SpaceID when refreshing access token");
    this.tokenFetchingState = i.fetching, this.fetchTokenResp = null;
    try {
      const s = await k.getOrCreateInstance().spacePermission().get(e);
      this.storeTokens(s), this.tokenFetchingState = i.ready, this.fetchTokenResp = s;
      return;
    } catch (s) {
      (t = s.msg) != null && t.includes("not valid") ? this.tokenFetchingState = i.errorFatal : this.tokenFetchingState = i.errorRetry;
    }
  }
  static removeTokens() {
    var e;
    try {
      (e = window == null ? void 0 : window.localStorage) == null || e.removeItem(a);
    } catch {
    }
  }
}
export {
  c as PTokenHandler
};
